import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { logout } from '../../../actions';

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout())
});

const mapStateToProps = state => ({
    auth: state.user.username
});

const NavRouted = ({ history, auth, logout }) => {
    let activeLink = pathname => {
        if (pathname === '/') {
            return history.location.pathname === pathname ? 'selected' : '';
        } else {
            return history.location.pathname.indexOf(pathname) === 0 ? 'selected' : '';
        }
    }
    return (
        <nav>
            {auth
                ?
                <ul>
                    <li>
                        <NavLink to={'/'} className={activeLink('/')}>home</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/account'} className={activeLink('/account')}>account</NavLink>
                    </li>
                    <li className={'box'}>
                        <NavLink to={'#'} onClick={() => { logout(); window.location.reload(); }}>logout</NavLink>
                    </li>
                </ul>
                :
                <ul>
                    <li>
                        <NavLink to={'/'} className={activeLink('/')}>home</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/login'} className={activeLink('/login')}>login</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/early-access'} className={activeLink('/early-access')}>early access</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/registration'} className={activeLink('/registration')}>register</NavLink>
                    </li>
                </ul>
            }
        </nav>
    );
};

const Nav = withRouter(connect(mapStateToProps, mapDispatchToProps)(NavRouted));

export default Nav;
