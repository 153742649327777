import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    doRequest,
    errorRead
} from '../../../actions';

import Error from '../../elements/Error';
import LinkMail from '../../elements/LinkMail';
import Modal from '../../elements/Modal';
import SchemaValidator from '../../../lib/SchemaValidator';
import ShakeElement from '../../../lib/ShakeElement';

import PrivacyPolicy from '../Main/PrivacyPolicy';

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error,
});

const mapDispatchToProps = dispatch => ({
    doRequest: payload => dispatch(doRequest('signup', payload)),
    errorRead: () => dispatch(errorRead())
});

const _EarlyAccess = ({ data, doRequest, errorRead, stateError }) => {

    const factions = [
        "activist",
        "crime",
        "police",
        "venture",
        "factionless"
    ];

    const [modal, setModal] = useState(false);
    const [processed, setProcessed] = useState(false);
    const [success, setSuccess] = useState(false);

    const [payload, setPayload] = useState(
        {
            email: "",
            faction: "factionless",
            privacy: 0
        }
    );

    const [errors, setErrors] = useState("");

    useEffect(
        () => {
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            if (errors.length > 0) {
                ShakeElement('form[name="early-access"]');
            }
            return () => null;
        },
        [errorRead, errors, setErrors, stateError],
    );

    const handleChange = (e) => {
        let _payload = payload;
        _payload[e.target.name] = e.target.value;
        if (e.target.type === 'checkbox') {
            _payload[e.target.name] = e.target.checked ? 1 : 0;
        }
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let v = SchemaValidator(payload, 'signup');
        if (v.errors.length > 0) {
            setErrors(v.errors.map(el => el.schema.message).join("\n"));
        } else {
            setProcessed(true);
            doRequest(payload);
        }
    }

    if (data && processed) {
        setProcessed(false);
        setSuccess('close');
    }

    return (
        <section>
            <h1>get early access</h1>
            <form name="early-access">
                {
                    success
                        ?
                        <div>
                            <legend>signed up!</legend>
                            <p>
                                Thank you for signin up! As soon as possible we'll review your early access request and eventually we'll send you an email to <strong>{data.email}</strong> with a confirmation link, so you'll be able to create a new account as a tester.
                            </p>
                            <LinkMail text={"If you don't receive anything remember to check the spam folder ;) For any issue write us to"} />
                        </div>
                        :
                        <div>
                            <legend>play the closed alpha!</legend>
                            <p>
                                We are selecting the first players to be involved as testers for the closed alpha release of the game.
                            </p>
                            <p>
                                If you want to be recruited and access exclusive privileges, select your Faction and request an invitation to the CyberDeck™ Team!
                            </p>
                            <fieldset>
                                <p>
                                    <input type="email" placeholder="your email here" name="email" required onChange={handleChange} />
                                </p>
                                <div>
                                    {factions.map(
                                        (faction, index) => {
                                            return (
                                                <div key={index} className="radioOption">
                                                    <img alt={faction} src={`/static/images/factions/${faction}.png`} />
                                                    <input
                                                        id={`faction_${index}`}
                                                        type="radio"
                                                        name="faction"
                                                        defaultValue={faction}
                                                        onChange={handleChange}
                                                    />
                                                    <label htmlFor={`faction_${index}`}>
                                                        <span />
                                                        {faction}
                                                    </label>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                                <p>
                                    <input type="checkbox" id="privacy" name="privacy" required onChange={handleChange} defaultValue="1" />
                                    <label className={'left'} htmlFor={`privacy`}>
                                        <span />
                                        I read and consent to your <Link to="#" onClick={() => setModal(true)}>privacy policy</Link>
                                    </label>
                                </p>
                                <Modal show={modal}>
                                    <div>
                                        <p className="right"><Link to="#" onClick={() => setModal(false)}>X Close</Link></p>
                                        <PrivacyPolicy />
                                    </div>
                                </Modal>
                                <p>
                                    <button className="ok" onClick={handleSubmit}>Sign up</button>
                                </p>
                                <Error text={errors} />
                                <LinkMail />
                            </fieldset>
                        </div>
                }
            </form>
        </section>
    );
};

const EarlyAccess = connect(mapStateToProps, mapDispatchToProps)(_EarlyAccess);

export default EarlyAccess;