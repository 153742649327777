import React from 'react';

const CookiePolicyText = () => {
  return (
    <div className="left boxed-text">
      <p>
        This cookie policy (the <strong>"Policy"</strong>) describes how we use cookies to improve your browsing experience.
      </p>
      <ol>
        <li>
          <big>What are cookies?</big>
          <br />
          Cookies are small text files that are stored in your browser by websites that you visit. They usually contain a website and an identifier.
          <br />
          Cookies may be used for various purposes, in general they can be divided in two categories:
          <ul>
            <li>
              <strong>Technical cookies</strong>
              <br />
              Technical cookies are used for the sole purpose of "carrying out the transmission of a communication on an electronic communications network, or insofar as this is strictly necessary to the provider of an information society service that has been explicitly requested by the contracting party or user to provide said service" and their use does not require the user’s consent
            </li>
            <li>
              <strong>Tracking cookies</strong>
              <br />
              Tracking cookies are aimed at creating user profiles and are used to send advertising messages in line with the preferences shown by the user during their navigation on the web. The users must be adequately informed on the use of such cookies and express their valid consent.
            </li>
            <br />
            Cookies are also divided by their origin: a <strong>First Party</strong> cookie is set by this Portal; a <strong>Thrid Party</strong> cookie is set by another website whose servioces are integrated in the Portal.
          </ul>
        </li>
        <li>
          <big>How we use cookies?</big>
          <br />
          We set only one First Party technical cookie which details are shown below:
          <table>
            <thead>
              <tr>
                <th>name</th>
                <th>category</th>
                <th>purpose</th>
                <th>duration</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>mybbuser</td>
                <td>First Party, technical</td>
                <td>automatic login in forum.cyberdeck.eu subdomain</td>
                <td>as long as the user session</td>
              </tr>
            </tbody>
          </table>
        </li>
      </ol>
    </div>
  );
};

export default CookiePolicyText;
