import React from 'react';
import { Link } from 'react-router-dom';

import Mailto from 'react-protected-mailto';

const GeneralTermsAndConditionsText = ({ date }) => {
  return (
    <div className="left boxed-text">
      <p className="right">
        <small>Last update on {(new Date(date).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' }))}.</small>
      </p>
      <p>THIS VERSION OF THE GENERAL TERMS AND CONDITIONS OF USE WILL BECOME EFFECTIVE ON JANUARY 2020.</p>
      <p>YOU SHOULD CAREFULLY READ THIS GENERAL TERMS AND CONDITIONS OF USE (THE <strong>"AGREEMENT"</strong>) BEFORE INSTALLING OR USING CYBERDECK PLATFORM AND GAMES.</p>
      <p>IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, YOU ARE NOT PERMITTED TO INSTALL OR USE THE CYBERDECK PLATFORM, NOR OTHERWISE ACCESS THE GAME.</p>
      <p><strong>Cyberdeck S.r.l.</strong> is a limited liability company established under the law of Italy, having its registered office in Milan (ITALY), Corso Venezia 54, 20122, VAT number 10381000966 (hereinafter referred to as <strong>"Cyberdeck"</strong>, <strong>"we"</strong> or <strong>"us"</strong>).</p>
      <p>Cyberdeck is the owner of Cyberdeck's online gaming service (Cyberdeck's games are referred to herein as the <strong>"Games"</strong>), which are provided through the Platform. As used herein, the term <strong>"Platform"</strong> refers collectively, and at times individually, to (i) the Cyberdeck Game's app software, (ii) the Cyberdeck's online gaming services, (iii) each of the games, (iv) authorized mobile apps software relating to the Games, and (v) all features and components of each of them, whether installed or used on a computer or mobile device.</p>
      <p>This Agreement sets forth the terms and conditions under which you are licensed by Cyberdeck to install and use the Platform. Except as otherwise provided below, wherever you reside, the general terms and conditions of use provided by this Agreement constitute a binding, legal agreement between you (hereinafter referred to as <strong>"you"</strong>), and Cyberdeck.</p>
      <ol>
        <li>
          <big>Cyberdeck Account</big>
          <br />
          <ol>
            <li>
              <b>Account registration</b>
              <ol type="i">
                <li>To use the Platform, you must register, or have previously registered, a Cyberdeck account (the <strong>"Account"</strong>). You are not allowed to have more than 3 (three) Accounts.</li>
                <li>
                  To establish an Account, you must:
                <ol type="a">
                    <li>be a <strong>"natural person"</strong> and be over 18 (eighteen) years old or an adult in your country of residence. Subject to the laws of your country of residence, minor children may utilize an Account established by their parent or legal guardian. In the event that you permit your minor child or legal ward (collectively, the <strong>"Child"</strong>) to use an Account on the Platform, you hereby agree to this Agreement on behalf of yourself and your Child, and you understand and agree that you will be responsible for all uses of the Account by your Child whether or not such uses were authorized by you;</li>
                    <li>provide Cyberdeck with your e-mail address. Cyberdeck’s retention and/or use of your personal information is subject to Cyberdeck Privacy Policy, located <Link to={'/privacy-policy'} target={'_blank'}>here</Link>. Cyberdeck shall also have the right to obtain non personal data from your connection to the Platform;</li>
                    <li>select a unique username and password (the <strong>"Login Information"</strong>).</li>
                  </ol>
                </li>
                <li>When you establish an Account, Cyberdeck will generate a wallet, which will be assigned to you (the <strong>"Personal Wallet"</strong>). In case of termination for any reason of this Agreement, you will conserve your Personal Wallet.</li>
                <li>You cannot share the Account or the Login Information with anyone, unless the terms of this Agreement allow it.</li>
                <li>You are the sole responsible to ensure no third party has access your Account(s) and you are deemed to be the sole responsible of any activity performed personally or by third parties via your Account, as regards both Cyberdeck and third parties.</li>
                <li>If you become aware of or reasonably suspect any breach of security, including without limitation any loss, theft, or unauthorized disclosure of the Login Information, you must immediately notify Cyberdeck’s customer service at <Mailto email={process.env.REACT_APP_EMAIL_ADDRESS} headers={{ subject: process.env.REACT_APP_EMAIL_SUBJECT }} />.</li>
              </ol>
            </li>
            <li>
              <b>Account deletion</b>
              <ol type="i">
                <li>You can delete your Account at any moment pursuant to Section 13; in such case your Account will be deleted.</li>
                <li>If you violate any term of this Agreement, Cyberdeck reserves the right to suspend or delete your Account. In case of minor violations, Cyberdeck, prior to deleting the Account and terminating this Agreement, may provide you with a warning and/or suspend your use of the Account due to your non-compliance.</li>
                <li>In any case of suspension or deletion of your Account, you will conserve your personal wallet.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <big>Grant of License</big>
          <br />
          <ol>
            <li>Pursuant to the terms of this Agreement, Cyberdeck will grant you the licence to install and use the Platform (including the Games) (the <strong>"Licence"</strong>). The Licence is free, limited, revocable, non-sub licensable, non-exclusive and subject to the Licence limitations (the <strong>"License Limitations"</strong>) set forth in Section 3 below.</li>
            <li>The Licence is subject, <em>inter alia</em>, to the following conditions:
              <ol type="i">
                <li>you may install applicable components or features of the Platform (including the Games) on one or more computers or mobile devices under your legitimate control;</li>
                <li>you may use the Platform for your personal and non-commercial entertainment purposes only, unless specifically allowed under the terms of this Agreement;</li>
                <li>you may not transfer your rights and obligations to use the Platform (including the Games);</li>
                <li>with regards to Games on original media (e.g., on CD-ROM, DVD, etc.) provided by Cyberdeck, you may permanently transfer all of your rights and obligations related to the use of a Game under this Agreement to another person who agrees to the terms and conditions of this Agreement by physically transferring the original media, original packaging, and all manuals or other documentation distributed with the Game, provided that you permanently delete all copies and installations of the Game in your possession or control.</li>
              </ol>
              <br />
              In case of violation of the Licence’s conditions set forth in this Section 2.2 Cyberdeck, in its sole judgement and discretion, may suspend or revoke your Licence, or parts, components and/or single features thereof.
            </li>
          </ol>
        </li>
        <li>
          <big>License Limitations</big>
          <br />
          <ol>
            <li>Without prejudice to provisions set forth under Section 2.2 above, Cyberdeck, in its sole judgement and discretion, may suspend or revoke your Licence, or parts, components and/or single features thereof, if you violate, or assist others in violating, the License Limitations set forth below. You agree that you will not, in whole or in part or under any circumstances:
            <ol type="i">
                <li>copy or reproduce, translate, reverse engineer, derive source code from, modify, disassemble, decompile, or create derivative works based on or related to the Platform (except for Custom Games, as defined in Section 5.1 below);</li>
                <li>create, use, offer, promote, advertise, make available and/or distribute the following or assist therein:
                  <ol type="a">
                    <li>cheats: i.e. methods not expressly authorized by Cyberdeck, influencing and/or facilitating the gameplay, including exploits of any in-game bugs, and thereby granting you and/or any other User an advantage over other players not using such methods;</li>
                    <li>bots: i.e. any code and/or software, not expressly authorized by Cyberdeck, that allows the automated control of a Game, or any other feature of the Platform (including the Games);</li>
                    <li>hacks: i.e. accessing or modifying the software of the Platform (including the Games) in any manner not expressly authorized by Cyberdeck; and/or</li>
                    <li>any code and/or software, not expressly authorized by Cyberdeck, that can be used in connection with the Platform (including the Games) and/or any component or feature thereof which changes and/or facilitates the gameplay or other functionality;</li>
                  </ol>
                </li>
                <li>exploit the Platform for any purpose not expressly authorized by Cyberdeck, including, without limitation  (a) gathering in-game items or resources for sale outside of the Platform or the Game(s); (b) performing in-game services including, without limitation, account boosting or power-leveling, in exchange for payment; (c) communicating or facilitating any commercial advertisement, solicitation or offer through or within the Platform;</li>
                <li>use the Platform for any esports or group competition sponsored, promoted or facilitated by any commercial or non-profit entity without obtaining Cyberdeck’s prior written consent. To obtain Cyberdeck’s prior written consent contact Cyberdeck customer service at <Mailto email={process.env.REACT_APP_EMAIL_ADDRESS} headers={{ subject: process.env.REACT_APP_EMAIL_SUBJECT }} />.</li>
                <li>do cloud computing;</li>
                <li>data mine;</li>
                <li>create, utilize or transact in any in-game item created or copied by exploiting a design flaw, undocumented problem, or program bug in the Platform;</li>
                <li>host, provide or develop matchmaking services for the Game(s), or intercept, emulate or redirect the communication protocols used by Cyberdeck in any way, for any purpose;</li>
                <li>facilitate, create or maintain any unauthorized connection to the Platform;</li>
                <li>attempt to sell, sublicense, rent, lease or otherwise transfer any copy of the Platform or component thereof, or your rights to the Platform to any other party in any way not expressly authorized herein;</li>
                <li>engage in any conduct intended to disrupt or diminish the game experience for other players, or disrupt operation of Cyberdeck’s Platform in any way;</li>
                <li>use the Platform to violate any applicable law or regulation.</li>
              </ol>
              <br />
              In case of violation of the Licence’s conditions set forth in this Section 2.2 Cyberdeck, in its sole judgement and discretion, may suspend or revoke your Licence, or parts, components and/or single features thereof.
            </li>
          </ol>
        </li>

        <li>
          <big>Platform Features</big>
          <br />
          <ol>
            <li><b>Cyberdeck Balance</b>
              <ol type="i">
                <li>As an active Account holder, you will hold a Cyberdeck balance (the <strong>"Cyberdeck Balance"</strong>). Cyberdeck Balance may consist of card packs which can be purchased on Cyberdeck Platform (the <strong>"Card Packs"</strong>) and/or tokens which can be gained playing Cyberdeck games (the <strong>"Tokens"</strong>). Cyberdeck Balance has no cash value, so that it is not redeemable or refundable for any sum of money or monetary value from Cyberdeck. </li>
                <li>In order to check your Cyberdeck Balance, access it on <strong>https://play.cyberdeck.eu/</strong> webpage. You are solely responsible for verifying that the content has been added to or deducted according to your uses of the Platform.</li>
                <li>You are responsible for all uses of your Cyberdeck Balance. If you suspect that your Cyberdeck Balance has been compromised, you should contact Cyberdeck’s customer service at <Mailto email={process.env.REACT_APP_EMAIL_ADDRESS} headers={{ subject: process.env.REACT_APP_EMAIL_SUBJECT }} /> so that the matter can be investigated by Cyberdeck. The latter will take actions to freeze your Account until the control of your Account can be returned to you.</li>
              </ol>
            </li>
            <li><b>Purchases</b>
              <ol type="i">
                <li>Purchases are allowed only on in-game goods like Card Packs or any other good or feature offered by the Platform itself, following the instructions provided to you on <strong>https://play.cyberdeck.eu/</strong> where available. Single cards, instead, may only be switched between holders.</li>
                <li>Cyberdeck does not provide payment services in accordance with current legal rules, relying for that purpose to the services provided by payment gateways, such as PayPal by PayPal Inc. or any other payment gateway accessible from Cyberdeck website or client under the rules in force.</li>
                <li>The currency for each purchase is Euro. For purchases outside the Euro zone, the exchange rate is demanded to each payment gateway.</li>
                <li>Transactions to purchase Card Packs or any other good or feature are governed by the terms of this Agreement and by the General Terms of Sale, located <Link to={'/general-terms-sale'} target={'_blank'}>here</Link>.</li>
              </ol>
            </li>
            <li>
              <b>Third-Party Tool Integrations</b>
              <br />
              The Platform may incorporate third-party technology that enables advertising on the Platform and/or in certain Games playable on the Platform, which may be downloaded temporarily to your personal computer and replaced during online game play. As part of this process, Cyberdeck and/or its authorized third-party advertisers may collect, in compliance with Cyberdeck Privacy Policy located <Link to={'/privacy-policy'} target={'_blank'}>here</Link>, standard information that is sent when your personal computer connects to the Internet including your Internet protocol (IP) address and any information your applications could send.
            </li>
            <li><b>User Created or Uploaded Content</b>
              <ol type="i">
                <li>The Platform may provide you an opportunity to post content on the Platform, such as on the Cyberdeck forums, and/or as part of a Game, including the compilation, arrangement or display of such content (the <strong>"User Content"</strong>). User Content specifically does not include a Custom Game, as defined in Section 5.1 below.</li>
                <li>You acknowledge and accept to be solely responsible for User Content and the consequences of its publication through the Platform. Cyberdeck does not endorse any User Content or any opinion, recommendation or advice contained in them, and expressly disclaims any and all liability in connection with User Content.</li>
                <li>You agree not to submit, post, publish, transmit, distribute, share, or otherwise publish or facilitate distribution through or on the Platform, of any User Content that:
                  <ol type="a">
                    <li>in any way, they are identified as illegal, threatening, harassing, abusive, defamatory, invasive of another’s privacy, offensive, libelous, deceptive, fraudulent, obscene, pornographic, indecent, vulgar, sexually explicit, profane;</li>
                    <li>constitute, encourage or incite a criminal offense;</li>
                    <li>are aimed to sexual exploitation;</li>
                    <li>violate the rights of other persons or of national or international laws;</li>
                    <li>describe, refer or otherwise communicate hatred or gender discrimination, sexual orientation, race, religion, or nationality;</li>
                    <li>contain swearing, offensive language or language that can annoy, harass, embarrass, frighten or disturb other subscribers;</li>
                    <li>contain statements or otherwise damaging the reputation of any person or entity;</li>
                    <li>have been copied by third parties;</li>
                    <li>damage the goodwill associated with the trademark or the name of Cyberdeck;</li>
                    <li>infringes any patent, trademark, trade secret, copyright, right of publicity or other rights of any other person or entity or violates any law or contractual obligation;</li>
                    <li>contain private information of any third party, including, without limitation, addresses, phone numbers, email addresses, credit card numbers, etc.;</li>
                    <li>activate chain letters (spam, instant messages etc.);</li>
                    <li>are false, misleading or inaccurate;</li>
                    <li>reveal professional secrets;</li>
                    <li>hide unauthorized commercial activities (including sales, contests, advertising, links to other web sites or pay telephone numbers);</li>
                    <li>in the sole judgment of Cyberdeck are objectionable or restrict or inhibit any other party from using or enjoying the Platform, or which may expose Cyberdeck, other people to damages or obligations of any kind.</li>
                  </ol>
                </li>
                <li>In the event you transmit to Cyberdeck any concepts, ideas, or feedback relating to the Platform, you shall not be entitled to any compensation for any such submission, unless expressly agreed between you and Cyberdeck, and Cyberdeck may freely use any such submission in any manner it deems appropriate. Any such submission by you shall not create any contractual relationship between you and Cyberdeck. Except to the extent that any such waiver is prohibited by law, you hereby waive the benefit of any provision of law known as intellectual property rights.</li>
              </ol>
            </li>
          </ol>
        </li>


        <li>
          <big>Game Features</big>
          <br />
          <ol>
            <li><b>Game Editors</b>
              <br />
              Certain Games include editing software (the <strong>"Game Editor(s)"</strong>) that will allow you to create scenarios or other content (the <strong>"Custom Games"</strong>). For purposes of this Agreement and any agreements referenced herein, Custom Games, which shall be deemed exclusively owned by you, includes all content created using the Game Editor(s), including but not limited to all digital files associated with such Custom Games, as well as (i) all content contained within such files, including but not limited to player and non-player characters, environments, objects, items, skins, and textures, (ii) all titles, trademarks, trade names, character names, or other names and phrases associated with or included within the Custom Game, and (iii) any other intellectual property rights contained within the Custom Game, including any and all content, game concepts, methods or ideas.
            </li>
            <li><b>Community Tournaments</b>
              <br />
              Subject to Cyberdeck prior written consent pursuant to Section 3.1 iv., in order to support local esports tournament activities, Cyberdeck provides a license program for organizers of community tournaments upon direct agreement with Cyberdeck. Contact Cyberdeck customer service at <Mailto email={process.env.REACT_APP_EMAIL_ADDRESS} headers={{ subject: process.env.REACT_APP_EMAIL_SUBJECT }} />.
            </li>
            <li><b>Beta Testing Pre-Release Versions of Games</b>
              <ol type="i">
                <li>Pre-release versions of Games are made available to you through the Platform for testing (<strong>"Beta Test"</strong>). Beta Test may be closed or open (respectively, the <strong>"Closed Beta Test"</strong> and the <strong>"Open Beta Test"</strong>).</li>
                <li>In order to participate in a Closed Beta Test, you must be designated by Cyberdeck as a beta tester (the <strong>"Closed Beta Tester"</strong>). In this case, after your request, Cyberdeck will send you an invitation to participate to the Closed Beta Test (the <strong>"Invitation"</strong>). After having received the Invitation, you must create an Account pursuant to Section 1.1 above.</li>
                <li>In order to participate in an Open Beta Test, you must create an Account pursuant to Section 1.1 above, without the need for the Invitation.</li>
                <li>If the Beta Test is to test an expansion into the Game, then the Account must be upgraded with all previous expansions to that particular Game version.</li>
                <li>In order to determine if you are eligible to participate in the Closed Beta Test, prior to your registration as Closed Beta Tester, you agree to allow Cyberdeck to obtain hardware and software information from the computer system that you will use to take part in the Beta Test (the <strong>"Beta Test System"</strong>), with the sole purpose to ensure that your Beta Test System meet the specifications that Cyberdeck requires for the Closed Beta Test.</li>
                <li>You acknowledge and agree that participation in the Beta Test under this Agreement will result in Cyberdeck disclosing certain confidential, proprietary and/or trade secret information related to the beta tested Games (the <strong>"Confidential Information"</strong>). Such Confidential Information includes, without limitation, the features, functionality and existence of the beta tested Game and any know how, trade secrets, computer programs, source code, flowcharts, diagrams, manuals, schematics, development tools, specifications, design documents, marketing information, financial information, business plans or reports made available to you. You agree that you will not, without the express prior written consent of Cyberdeck, disclose any Confidential Information or any part thereof to any third-party, except to the extent that such Confidential Information (a) is or becomes generally available to the public through any means other than as a result of any act or omission by you; (b) is rightfully received by you from a third-party that is not subject to any obligation of confidentiality with respect thereto and without limitation as to its use; or (c) is independently developed by you without any reliance on any Confidential Information.</li>
                <li>During and after the Beta Test, you may be provided with an opportunity to give Cyberdeck your comments, recommendations and impressions of the beta tested Game. Feedback shall include informing Cyberdeck about the performance, ease of use, features that may be missing, and any bugs encountered during the Beta Test. The beta tested Game may also include a tool that will allow your computer system to forward system and driver information to Cyberdeck in the event of a crash. This tool will collect data from your Beta Test System related to the crash, and allow you to forward a report to Cyberdeck via e-mail at <Mailto email={process.env.REACT_APP_EMAIL_ADDRESS} headers={{ subject: process.env.REACT_APP_EMAIL_SUBJECT }} />.</li>
                <li>You acknowledge that:
                  <ol type="a">
                    <li>the Game being beta tested is a work in progress and may contain bugs which may cause loss of data and/or damage to your computer system;</li>
                    <li>you have, or will, back-up your hard drive prior to installation of the Beta Test;</li>
                    <li>you have the resources necessary to easily reinstall the Beta Test System’s operating system and restore any and all data that may be lost;</li>
                    <li>Cyberdeck shall not be liable in any way for interruptions of service, software or hardware failures, or disruption of service;</li>
                    <li>Cyberdeck shall not be liable for any loss or damage arising out of your participation in the Beta Test;</li>
                    <li>Cyberdeck may delete or modify the information stored by the Platform or the Game being beta tested for any reason at any time during the duration of the Beta Test;</li>
                    <li>Cyberdeck may transfer software program files to the Beta Test System, including a program that will collect and send Cyberdeck CPU, RAM, operating system, video card, and sound card information from the Beta Test System;</li>
                    <li>you may not sell, transfer or commercially exploit access to a Beta Test, including the distribution of Beta keys without Cyberdeck’s express authorization;</li>
                    <li>Cyberdeck can terminate a Beta Test at any time. When Cyberdeck terminates a Beta Test, you must delete the pre-release version of the beta tested Game and all documents and materials you received from Cyberdeck in connection with the Beta Test. You may be asked by Cyberdeck to remove any elements of the Beta Test from any hard drives on which the pre-release version of the beta tested Game has been installed. You agree and acknowledge that Cyberdeck’s termination of the Beta Test shall not be grounds for any refunds of any kind.</li>
                    <li>When participating in a Beta Test, the terms of this Section 5.3 shall supersede and govern over any other Section of this Agreement which may be in conflict with the terms of this Section 5.3.</li>
                  </ol>
                </li>
              </ol>
              <br />
            </li>
          </ol>
        </li>
        <li><big>Platform Ownership</big>
          <ol>
            <li>This License does not grant any rights, title or interest in any intellectual property right to the Platform, including the Games, and all of the features and components thereof. All the techniques, algorithms, procedures contained in Platform are information protected by Copyright and are Cyberdeck’s property: Therefore, they cannot be used in any way by you for purposes other than those indicated in this Agreement.</li>
            <li>The Platform may contain materials licensed by third parties to Cyberdeck, and these third parties may enforce their ownership rights against you in the event that you violate this Agreement. Cyberdeck shall not be deem liable for any violation that you may cause to any third party.</li>
            <li>All virtual content appearing within the Platform, including the Games, all data and communications generated by, or occurring through, the Platform, all sounds, musical compositions, recordings, and sound effects originating in the Platform, all recordings, Game replays, or reenactments of in-game matches, battles, duels, etc., all computer code, all titles, methods of operation, software, related documentation, and all other original works of authorship contained in the Platform; all moral rights that relate to the Platform, including Custom Games derived from a Cyberdeck Game and the right to create derivative works are owned or licensed by Cyberdeck.</li>
          </ol>
        </li>
        <li>
          <big>Pre-Loaded Software</big>
          <ol>
            <li>The Platform may contain additional software that requires you to agree to additional terms prior to your use thereof (<strong>"Additional Software"</strong>).</li>
            <li>You agree that Cyberdeck may install Additional Software on your hard drive as part of the installation of the Platform, and from time to time during the term of this Agreement.</li>
            <li>Unless Cyberdeck grants you a valid license and alphanumeric key to use and activate the Additional Software, you may not access, use, distribute, copy, display, reverse engineer, derive source code from, modify, disassemble, decompile or create derivative works based on the Additional Software. In the event that Cyberdeck grants to you a valid license and alphanumeric key to use and activate the Additional Software, all use of the Additional Software shall be subject to the terms of this Agreement.</li>
          </ol>
        </li>

        <li>
          <big>Consent to Monitor</big>
          <br />
          While running, the Platform (including a Game) may monitor your computer or mobile device’s memory for unauthorized third-party programs running either concurrently with a game or out of process. An <strong>"unauthorized third-party program"</strong> as used herein shall be defined as any third-party software prohibited by Section 3 above. In the event that the Platform detects an unauthorized third-party program, (i) the Platform may communicate information back to Cyberdeck, including without limitation your account name, details about the unauthorized third-party program detected, and the time and date; and/or (ii) Cyberdeck may exercise any or all of its rights under this agreement, with or without prior notice to the User. Additionally, certain Games include a tool that will allow your computer system to forward information to Cyberdeck in the event that the Game crashes, including system and driver data, and by agreeing hereto you consent to Cyberdeck receiving and/or using this data under Cyberdeck Privacy Policy, located <Link to={'/privacy-policy'} target={'_blank'}>here</Link>.
        </li>

        <li>
          <big>Limitations of Liability</big>
          <ol>
            <li>The Platform, Accounts and games are provided "as is" and "as available". Cyberdeck, its parent, subsidiaries, affiliates shall not be liable for any loss or damage arising out of your use of, or inability to access or use, the Platform or Account(s).</li>
            <li><b>Platform</b>
              <br />
              Cyberdeck will not be held responsible for:
              <ol type="i">
                <li>the use of the Platform and Games, nor that the Platform (including the Games) will meet your needs;</li>
                <li>bany information obtained by you as a result of your use of the Platform, or guarantee that it is accurate or reliable;</li>
                <li>defects in the operation or functionality of any software provided to you as part of the Platform;</li>
                <li>that the Platform is available without interruptions, risks or errors;</li>
                <li>technical malfunctions of hardware or software, interruptions and breaks in network connection, failed registrations, incorrect, inaccurate, incomplete, unreadable, damaged, lost, retarded, incorrectly addressed or not received, electronic or other kind of  communications incorrect, inaccurate, incomplete, unreadable, damaged, lost, retarded, incorrectly addressed or not received, any problem in the User Content upload and problem of functioning caused by technical, maintenance and installation defects.</li>
              </ol>
            </li>
            <li><b>User Content</b>
              <ol type="i">
                <li>
                  Pursuant to Legislative Decree n. 70 of 9 April 2003 and Directive 2000/31/EC, Cyberdeck, as the provider of information society services, it is not subject to any obligation to verify or responsible for any declaration or any User Content inserted (of any kind and in any way) by you on the Platform. Cyberdeck reserves, however, the right, in its sole judgment and discretion, to monitor, remove, refuse, disable access, monitor or edit any User Content at any time and for any reason without prior notice. Cyberdeck reserves the right, in its sole judgment and discretion, to determine whether and what action to take in response to a User Content. In particular, in the event of a breach by this Agreement, Cyberdeck, when necessary, will provide, at its own discretion:
                  <ol type="a">
                    <li>the removal of the User Content;</li>
                    <li>the suspension or the deletion of your Account;</li>
                    <li>report infringement to the competent authorities.</li>
                  </ol>
                </li>
                <li>Cyberdeck assumes no liability towards Users and third parties and cannot be held responsible for what concerns the contents and activities carried out by you on or through the Platform. Cyberdeck disclaims all warranties relating to the information and / or User Contents posted by Users and in particular with regard to the fact that they present inaccurate content, improper, offensive, sexually explicit, scurrilous and / or defamatory of any person, or made in violation of third-party copyright or the right to privacy of the people filmed in the User Contents. Cyberdeck reserves the right to investigate and take the necessary legal action against who violate this Agreement.</li>
                <li>Without prejudice to the provisions of the preceding paragraph of this Section, Cyberdeck will not be liable to Users and / or third parties for damages caused to the subjects taken in the User Content and / or holders of assets of any kind (trademarks, audio files, artistic and graphic works) and protected by copyright or other intellectual property rights in any content inserted by Users in violation of the provisions of this Agreement.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <big>Indemnity</big>
          <br />
          You hereby agree to defend and indemnify Cyberdeck, its parent, subsidiaries, affiliates against and from any third party claims, liabilities, losses, injuries, damages, costs or expenses incurred by Cyberdeck arising out of or from your use of the Platform or Account(s), or any specific features associated therewith, including but not limited to User Content, Custom Games, Beta Test, Cyberdeck Balance, and this Agreement.
        </li>
        <li>
          <big>Amendments</big>
          <ol>
            <li>
              <b>Amendments to the Agreement</b>
              <br />
              Cyberdeck may create updated versions of this Agreement (each a <strong>"New Agreement"</strong>). In this circumstance, this Agreement will terminate immediately upon the introduction of a New Agreement. You will be given the opportunity to review the New Agreement before choosing to accept or reject its terms. In case of acceptance you will have the right to continue using the Platform and Account(s). In case of rejection you will no longer be permitted to use the Platform or Account(s).
            </li>
            <li>
              <b>Amendments to the Platform</b>
              <br />
              Cyberdeck may change, modify, suspend, or discontinue any aspect of the Platform or Accounts at any time, including removing items, or revising the effectiveness of items in an effort to balance a Game. Cyberdeck may also impose limits on certain features or restrict your access to parts or all of the Platform or Accounts without notice or liability.
            </li>
          </ol>
        </li>
        <li>
          <big>Duration</big>
          <br />
          This Agreement is effective upon your creation of an Account and shall remain in effect until it is terminated or superseded by a New Agreement, or, if neither of the foregoing events occur, as long as you continue using the Platform.
        </li>
        <li>
          <big>Termination</big>
          <ol>
            <li>
              You are entitled to terminate this Agreement at any time by notifying Cyberdeck by e-mail at <Mailto email={process.env.REACT_APP_EMAIL_ADDRESS} headers={{ subject: process.env.REACT_APP_EMAIL_SUBJECT }} />. Upon receipt of the notification of termination your Account will be deleted and you will conserve your personal wallet.
            </li>
            <li>
              Cyberdeck, at any time and at its sole discretion, reserves the right to terminate this Agreement in case of violation of its provisions, or where Cyberdeck must withdraw pursuant to law, to terminate its activities as well as for any other reason of opportunity.
            </li>
            <li>
              The termination of this Agreement implies the automatic deletion of the Account and Login Information for access and use of the Platform.
            </li>
          </ol>
        </li>
        <li>
          <big>Governing Law and Jurisdiction</big>
          <br />
          This Agreement shall be governed by, and will be construed under, the laws of Italy. For any dispute that may arise regarding the validity, the execution, the interpretation or the termination of the Agreement exclusively referred to the Court of Milan.
        </li>
        <li>
          <big>Notices</big>
          <ol>
            <li>If to Cyberdeck: all notices given by you under this Agreement shall be in writing and addressed to: Cyberdeck S.r.l. Corso Venezia 54, 20120 Milan (ITALY) or <Mailto email={process.env.REACT_APP_EMAIL_ADDRESS} headers={{ subject: process.env.REACT_APP_EMAIL_SUBJECT }} />.</li>
            <li>If to you: all notices given by Cyberdeck under this Agreement shall be given to you either through e-mail or website Cyberdeck post.</li>
          </ol>
        </li>
        <li>
          <big>General</big>
          <ol>
            <li>Cyberdeck may assign this Agreement, in whole or in part, to any person or entity at any time with or without your consent. You may not assign this Agreement without Cyberdeck’s prior written consent. Your assignment of this Agreement without Cyberdeck’s prior written consent shall be void.</li>
            <li>Cyberdeck’s failure to enforce a provision of this Agreement shall not be construed as a (i) waiver of such provision, or (ii) diminishment of any right to enforce such provisions. Further, Cyberdeck may choose to waive enforcement of a provision of this Agreement in a particular instance; however, you are still obligated to comply with that waived provision in the future.</li>
            <li>If any part of this Agreement is determined to be invalid or unenforceable, then that portion shall be severed, and the remainder of this Agreement shall be given full force and effect.</li>
            <li>This Agreement, along with Cyberdeck Privacy Policy and General Terms of Sale located <Link to={'/general-terms-sale'} target={'_blank'}>here</Link>, constitutes and contains the entire agreement between the parties with respect to the subject matter hereof and supersedes any prior oral or written agreements.</li>
            <li>The provisions of Sections 6, 9, 10, 14, 15, and 16 shall survive termination of this Agreement for any reason.</li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default GeneralTermsAndConditionsText;
