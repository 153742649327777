import {
    DO_REQUEST,
    ERROR_READ,
    GET_PROFILE,
    LOGGED,
    LOGIN,
    LOGIN_FAILED,
    LOGOUT,
    ME,
    REQUEST_FAILED,
    REQUEST_SUCCESS,
    RESET,
    SET_TOKEN
} from '../constants';

export const doRequest = (request, payload = null) => ({ type: DO_REQUEST, request, payload });
export const errorRead = () => ({ type: ERROR_READ });
export const getProfile = () => ({ type: GET_PROFILE });
export const logged = payload => ({ type: LOGGED, payload });
export const login = payload => ({ type: LOGIN, payload });
export const loginFailed = (payload) => ({ type: LOGIN_FAILED, payload });
export const logout = () => ({ type: LOGOUT });
export const me = payload => ({ type: ME, payload });
export const requestFailed = error => ({ type: REQUEST_FAILED, error });
export const requestSuccess = data => ({ type: REQUEST_SUCCESS, data });
export const reset = () => ({ type: RESET });
export const setToken = token => ({ type: SET_TOKEN, token });
