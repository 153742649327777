import React from 'react';
import { connect } from 'react-redux';

import EmailChange from '../../elements/Forms/EmailChange';
import PasswordChange from '../../elements/Forms/PasswordChange';
import UserDelete from '../../elements/Forms/UserDelete';

const mapStateToProps = state => ({
    user: state.user
});

const _Account = ({ user }) => {

    return (
        <section className="large">
            <h1><strong>{user.username}</strong>'s account</h1>
            <div className="grid">
                <EmailChange />
                <PasswordChange />
                <UserDelete />
            </div>
        </section>
    );
};

const Account = connect(mapStateToProps, null)(_Account);

export default Account;