import React from 'react';
import SimpleBarReact from "simplebar-react";

import PrivacyPolicyText from '../../elements/Texts/PrivacyPolicyText';

import '../../css/simplebar.min.css';

const PrivacyPolicy = () => {

    return (
        <section className="large">
            <h1>privacy policy</h1>
            <SimpleBarReact className="boxed" autoHide="true">
                <PrivacyPolicyText date={process.env.REACT_APP_PRIVACY} />
            </SimpleBarReact>
        </section>
    );
};

export default PrivacyPolicy;