export const DO_REQUEST = "DO_REQUEST";
export const ERROR_READ = "ERROR_READ";
export const GET_PROFILE = "GET_PROFILE";
export const LOGGED = "LOGGED";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const ME = "ME";
export const REQUEST_FAILED = "REQUEST_FAILED";
export const REQUEST_SUCCESS = "REQUEST_SUCCESS";
export const RESET = "RESET";
export const SET_TOKEN = "SET_TOKEN";
