import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';

import { doRequest } from '../../../actions';
import { errorRead } from '../../../actions';
import Error from '../../elements/Error';
import LinkMail from '../../elements/LinkMail';
import Modal from '../../elements/Modal';
import SchemaValidator from '../../../lib/SchemaValidator';
import ShakeElement from '../../../lib/ShakeElement';

import GeneralTermsAndConditions from '../Main/GeneralTermsAndConditions';
import GeneralTermsSale from '../Main/GeneralTermsSale';
import PrivacyPolicy from '../Main/PrivacyPolicy';

const urlPropsQueryConfig = {
    t: { type: UrlQueryParamTypes.string },
}

const mapDispatchToProps = (dispatch) => ({
    doRequest: payload => dispatch(doRequest('signin', payload)),
    errorRead: () => dispatch(errorRead()),
    getRegistrationToken: token => dispatch(doRequest('getRegistrationToken', token))
});

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error
});

const _Registration = ({ data, errorRead, doRequest, getRegistrationToken, stateError, t }) => {
    const [canRegister, setCanRegister] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [loadedData, setLoadedData] = useState(false);
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false);
    const [processed, setProcessed] = useState(false);
    const [success, setSuccess] = useState(false);

    const [payload, setPayload] = useState(
        {
            email: "",
            username: "",
            password: "",
            code: "",
            privacy: 0,
            gtcu: 0,
            gtcu_1: 0,
            gts: 0,
            gts_1: 0
        }
    );

    const [errors, setErrors] = useState("");

    useEffect(
        () => {
            if (t) {
                if (!loadedData && !firstLoad) {
                    getRegistrationToken(t);
                    setTimeout(() => setLoadedData(true), 500);
                    setFirstLoad(true);
                } else {
                    setFirstLoad(true);
                    setLoaded(true);
                }
            }
            if (!t || loaded) {
                if (stateError) {
                    setErrors(stateError);
                    errorRead();
                }
                if (errors.length > 0) {
                    ShakeElement('form[name="registration"]');
                }
            }
            return () => null;
        },
        [errorRead, errors, firstLoad, getRegistrationToken, loaded, loadedData, setErrors, setFirstLoad, setLoadedData, stateError, t],
    );

    const handleChange = (e) => {
        let _payload = payload;
        _payload[e.target.name] = e.target.value;
        if (e.target.type === 'checkbox') {
            _payload[e.target.name] = e.target.checked ? 1 : 0;
        }
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handleRegistration = (e) => {
        e.preventDefault();
        let v = SchemaValidator(payload, (canRegister ? 'registration' : 'registration-code'));
        if (v.errors.length > 0) {
            setErrors(v.errors.map(el => el.schema.message).join("\n"));
        } else {
            if (canRegister) {
                payload.token = t;
            }
            setProcessed(true);
            doRequest(payload);
        }
    }
    if (data && loadedData) {
        setCanRegister(true);
        setLoaded(true);
        setLoadedData(false);
    }

    if (data && processed) {
        setProcessed(false);
        setSuccess(true);
    }

    return (
        <section>
            <h1>registration</h1>
            {
                success
                    ?
                    <form>
                        <legend>Registration complete!</legend>
                        <p>
                            We just sent you an email to <strong>{data.email}</strong> with an activation link.
                        </p>
                        <LinkMail text={"If you don't receive anything remember to check the spam folder ;) For any issue write us to"} />
                    </form>
                    :
                    <form name="registration">
                        {
                            canRegister
                                ? <legend>Complete your registration by creating an account</legend>
                                : <legend>The game is now in <strong>closed alpha</strong> so you need an invitation code to join the tester community</legend>
                        }
                        <fieldset>
                            <p>
                                <input type="text" placeholder="choose an username" name="username" required onChange={handleChange} />
                                <label>minimum 3 chars, maximum 13 chars, only numbers and english alphabet letters</label>
                            </p>
                            {
                                canRegister
                                    ? <></>
                                    : <p>
                                        <input type="email" placeholder="insert your email" name="email" required onChange={handleChange} />
                                        <label></label>
                                    </p>
                            }
                            <p>
                                <input type="password" placeholder="choose a password" name="password" required onChange={handleChange} />
                                <label>minimum 8 chars with at least 1 capital letter, 1 number and 1 special char (!_.-$)</label>
                            </p>
                            {
                                canRegister
                                    ? <></>
                                    : <p>
                                        <input type="text" placeholder="invitation code here" name="code" required onChange={handleChange} />
                                        <label>the code you received via email</label>
                                    </p>
                            }
                            <Modal show={modal}>
                                <div>
                                    <p className="right"><Link to="#" onClick={() => setModal(false)}>X Close</Link></p>
                                    <PrivacyPolicy />
                                </div>
                            </Modal>
                            <p>
                                <input type="checkbox" id="privacy" name="privacy" required onChange={handleChange} defaultValue="1" />
                                <label className={'left'} htmlFor={`privacy`}>
                                    <span />
                                    I read and consent to your <Link to="#" onClick={() => setModal(true)}>privacy policy</Link>
                                </label>
                            </p>
                            <p>
                                <input type="checkbox" id="gtcu" name="gtcu" required onChange={handleChange} defaultValue="1" />
                                <label className={'left'} htmlFor={`gtcu`}>
                                    <span />
                                    I read and consent to your <Link to="#" onClick={() => setModal2(true)}>general terms and condition of use</Link>
                                </label>
                            </p>
                            <p>
                                <input type="checkbox" id="gtcu_1" name="gtcu_1" required onChange={handleChange} defaultValue="1" />
                                <label className={'left'} htmlFor={`gtcu_1`}>
                                    <span />
                                    Pursuant to articles 1341 and 1342 of the Italian Civil Code, I specifically accept the following Sections of the above Agreement: 2 "<b>Grant of Licence</b>", 3 "<b>Licence Limitations</b>", 4 "<b>Platform Features</b>", 6 "<b>Platform Ownership</b>", 7 "<b>Pre-Loaded Software</b>", 8 "<b>Consent to Monitor</b>", 9 "<b>Limitations of Liability</b>", 10 "<b>Indemnity</b>", 11 "<b>Amendments</b>", 12 "<b>Duration</b>", 13 "<b>Termination</b>", 14 "<b>Governing Law and Jurisdiction</b>", 16 "<b>General</b>".
                                </label>
                            </p>
                            <Modal id={"modal2"} show={modal2}>
                                <div>
                                    <p className="right"><Link to="#" onClick={() => setModal2(false)}>X Close</Link></p>
                                    <GeneralTermsAndConditions />
                                </div>
                            </Modal>
                            <p>
                                <input type="checkbox" id="gts" name="gts" required onChange={handleChange} defaultValue="1" />
                                <label className={'left'} htmlFor={`gts`}>
                                    <span />
                                    I read and consent to your <Link to="#" onClick={() => setModal3(true)}>general terms of sale</Link>
                                </label>
                            </p>
                            <p>
                                <input type="checkbox" id="gts_1" name="gts_1" required onChange={handleChange} defaultValue="1" />
                                <label className={'left'} htmlFor={`gts_1`}>
                                    <span />
                                    Pursuant to articles 1341 and 1342 of the Italian Civil Code, I specifically accept the following Sections of the above Agreement: 3 "<b>Liability for Unauthorized Transactions</b>", 5 "<b>Terms of payment</b>", 7 "<b>Company’s Undertakings and Warranty on Cyberdeck Content</b>", 8 "<b>Customer’s right to withdraw and refund</b>", 9 "<b>Amendments to General Terms of Sale</b>", 10 "<b>Governing Law and Jurisdiction</b>".
                                </label>
                            </p>
                            <Modal id={"modal3"} show={modal3}>
                                <div>
                                    <p className="right"><Link to="#" onClick={() => setModal3(false)}>X Close</Link></p>
                                    <GeneralTermsSale />
                                </div>
                            </Modal>
                            <p>
                                <button className="ok" onClick={handleRegistration}>Sign in</button>
                            </p>
                        </fieldset>
                        <Error text={errors} />
                        {
                            canRegister
                                ? <></>
                                : <p>Wanna be a tester? <Link to={'/early-access'}>Get early access now</Link></p>
                        }
                        <LinkMail text={"Getting trouble signin in? Write to"} />
                    </form>
            }
        </section>
    );
};

const Registration = addUrlProps({ urlPropsQueryConfig })(connect(mapStateToProps, mapDispatchToProps)(_Registration));

export default Registration;