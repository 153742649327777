import React from 'react';
import SimpleBarReact from "simplebar-react";

import CookiePolicyText from '../../elements/Texts/CookiePolicyText';

import '../../css/simplebar.min.css';

const CookiePolicy = () => {

    return (
        <section className="large">
            <h1>cookie policy</h1>
            <SimpleBarReact className="boxed" autoHide="true">
                <CookiePolicyText />
            </SimpleBarReact>
        </section>
    );
};

export default CookiePolicy;