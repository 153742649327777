import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  DO_REQUEST,
  GET_PROFILE,
  LOGIN,
  LOGOUT,
  SET_TOKEN
} from '../constants';
import {
  loginFailed,
  logged,
  me,
  requestFailed,
  requestSuccess,
  reset
} from '../actions';
import * as Cyberdeck from '../api/Cyberdeck';

function* callApi(action) {
  if (Cyberdeck[action.request] === undefined) {
    yield put(requestFailed(`The request cannot be fulfilled because method ${action.request} does not exist`));
  } else {
    var [data, code] = yield call(Cyberdeck[action.request], action.payload);
    if (code === 200 || code === 201) {
      yield put(requestSuccess(data));
    } else if (code === 401) {
      yield put(loginFailed("Your session is expired, please log in again"));
    } else {
      var errors = data || process.env.REACT_APP_ERROR_TEXT;
      yield put(requestFailed(`${errors}`));
    }
  }
}

function* login(action) {
  let { username, password } = action.payload;
  let [data, code] = yield call(Cyberdeck.login, username, password);
  if (code === 201) {
    let { token, user } = data;
    let { bb } = user;
    Cyberdeck.setToken(token);
    if (bb) {
      Cyberdeck.createCookies(bb.uid, bb.loginkey);
    }
    yield put(logged(user));
  } else {
    yield put(loginFailed(data || "login failed, please try again"));
  }
}

function* logout() {
  Cyberdeck.unsetToken();
  Cyberdeck.deleteCookies();
  yield put(reset());
}

function* loginWatcher() {
  yield takeLatest(LOGIN, login);
}

function* logoutWatcher() {
  yield takeLatest(LOGOUT, logout);
}

function* profileWatcher() {
  yield takeLatest(GET_PROFILE, setUserProfile);
}

function* requestWatcher() {
  yield takeLatest(DO_REQUEST, callApi);
}

function* setTokenWatcher() {
  yield takeLatest(SET_TOKEN, writeToken);
}

function* setUserProfile() {
  let token = Cyberdeck.getToken();
  if (token) {
    let [data, code] = yield call(Cyberdeck.getUser);
    if (code === 200) {
      let { bb } = data;
      if (bb) {
        Cyberdeck.createCookies(bb.uid, bb.loginkey);
      }
      yield put(me(data));
    } else {
      Cyberdeck.unsetToken();
      Cyberdeck.deleteCookies();
      yield put(reset());
    }
  } else {
    yield put(reset());
  }
}

function* writeToken(action) {
  Cyberdeck.setToken(action.token);
  yield put(requestSuccess(true));
}

export default function* rootSaga() {
  yield all([
    loginWatcher(),
    logoutWatcher(),
    profileWatcher(),
    requestWatcher(),
    setTokenWatcher()
  ]);
}
