import {
  DO_REQUEST,
  ERROR_READ,
  GET_PROFILE,
  LOGIN,
  LOGIN_FAILED,
  LOGGED,
  ME,
  REQUEST_FAILED,
  REQUEST_SUCCESS,
  RESET
} from '../constants';

const initialState = {
  data: null,
  error: null,
  loading: false,
  user: {
    balance: 0,
    blocked_time: null,
    change_email: false,
    email: null,
    privacy_date: null,
    username: null
  }
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case DO_REQUEST:
    case GET_PROFILE:
    case LOGIN:
      return {
        ...state,
        data: null,
        loading: true,
      };
    case ERROR_READ:
      return {
        ...state,
        error: null,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
        user: initialState.user
      };
    case LOGGED:
      return {
        ...state,
        error: null,
        loading: false,
        user: action.payload
      };
    case ME:
      return {
        ...state,
        loading: false,
        user: action.payload
      };
    case REQUEST_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case REQUEST_SUCCESS:
      return {
        ...state,
        data: action.data || true,
        error: null,
        loading: false,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default rootReducer;
