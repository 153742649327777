import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
} from "react-router-dom";
import { connect } from "react-redux";

import {
    getProfile
} from './actions';

import Header from './components/sections/Header';
import Main from './components/sections/Main';
import Footer from './components/sections/Footer';
import Loader from './components/elements/Loaders';

const mapDispatchToProps = (dispatch) => ({
    getProfile: () => dispatch(getProfile())
});

const _RoutedApp = ({ getProfile }) => {
    const [firstLoad, setFirstLoad] = useState(false);

    useEffect(() => {
        if (!firstLoad) {
            getProfile();
            setTimeout(() => { setFirstLoad(true); }, 500);
        };
        return () => setFirstLoad(true);
    }, [firstLoad, getProfile, setFirstLoad]);

    return (
        firstLoad
            ?
            <Router>
                <Header />
                <Main />
                <Footer />
                <Loader />
            </Router>
            :
            null
    );
};

const RoutedApp = connect(null, mapDispatchToProps)(_RoutedApp);

export default RoutedApp;