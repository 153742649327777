import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { doRequest, errorRead } from '../../../actions';
import Error from '../../elements/Error';
import LinkMail from '../../elements/LinkMail';
import SchemaValidator from '../../../lib/SchemaValidator';
import ShakeElement from '../../../lib/ShakeElement';

const mapDispatchToProps = (dispatch) => ({
    doRequest: payload => dispatch(doRequest('usernameRecovery', payload)),
    errorRead: () => dispatch(errorRead())
});

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error
});

const _UsernameRecovery = ({ data, errorRead, doRequest, stateError }) => {

    const [payload, setPayload] = useState(
        {
            email: ""
        }
    );

    const [errors, setErrors] = useState("");
    const [processed, setProcessed] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(
        () => {
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            if (errors.length > 0) {
                ShakeElement('form[name="usernamerecovery"]');
            }
            return () => null;
        },
        [errorRead, errors, setErrors, stateError],
    );

    const handleChange = (e) => {
        let _payload = payload;
        _payload[e.target.name] = e.target.value;
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handleUsernameRecovery = (e) => {
        e.preventDefault();
        let v = SchemaValidator(payload, 'recovery');
        if (v.errors.length > 0) {
            setErrors(v.errors.map(el => el.schema.message).join("\n"));
        } else {
            setProcessed(true);
            doRequest(payload);
        }
    }

    if (data && processed) {
        setProcessed(false);
        setSuccess(true);
    }

    return (
        <section>
            <h1>username recovery</h1>
            {
                success
                    ?
                    <form>
                        <legend>Recover en route!</legend>
                        <p>
                            We just sent you an email with the requested information if the address matches an active account.
                        </p>
                        <LinkMail text={"If you don't receive anything remember to check the spam folder ;) For any issue write us to"} />
                    </form>
                    :
                    <form name="usernamerecovery">
                        <legend>Type your email, we'll send your username if the address is tied to an active account</legend>
                        <fieldset>
                            <p>
                                <input type="email" placeholder="insert your email" name="email" required onChange={handleChange} />
                            </p>
                            <p>
                                <button className="ok" onClick={handleUsernameRecovery}>Recover</button>
                            </p>
                        </fieldset>
                        <Error text={errors} />
                        <LinkMail />
                    </form>
            }
        </section>
    );
};

const UsernameRecovery = connect(mapStateToProps, mapDispatchToProps)(_UsernameRecovery);

export default UsernameRecovery;