import axios from 'axios';

const makeRequest = ({ endpoint, method = 'GET', data = null, token = false }) => {

    const headers = {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
    };
    if (token) {
        headers['Authorization'] = `Bearer ${getToken()}`;
    }

    const request = {
        headers,
        method,
        url: process.env.REACT_APP_API_URL + `/${endpoint}`
    };
    if (data) {
        request.data = JSON.stringify(data);
    }

    return request;
};

const call = async request => {
    const { url } = request;

    try {
        const response = await axios.request(url, request);
        const { data, status } = response;
        return [data, status];
    } catch (error) {
        const { response } = error;
        if (response) {
            const { data, status } = response;
            return (status === 502 || status === 504) ? [process.env.REACT_APP_NOSERVER_TEXT, status] : [data, status];
        } else {
            return [process.env.REACT_APP_NOSERVER_TEXT, 502];
        }
    }
}

export const createCookies = (uid, loginkey) => document.cookie = "mybbuser=" + uid + "_" + loginkey + ";path=/;domain=.cyberdeck.eu";
export const deleteCookies = () => document.cookie = "mybbuser=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=.cyberdeck.eu";
export const emailChange = payload => call(makeRequest({ endpoint: 'emails', method: 'PUT', data: payload, token: true }));
export const emailChangeConfirm = payload => call(makeRequest({ endpoint: 'emails', method: 'PUT', data: payload, token: true }));
export const emailChangeDismiss = () => call(makeRequest({ endpoint: 'emails', method: 'DELETE', token: true }));
export const enroll = payload => call(makeRequest({ endpoint: 'users', method: 'PUT', data: payload }));
export const getAvatar = () => call(makeRequest({ endpoint: 'avatar', token: true }));
export const getActivationToken = token => call(makeRequest({ endpoint: `users/${token}` }));
export const getDownloads = () => call(makeRequest({ endpoint: 'downloads' }));
export const getPasswordToken = token => call(makeRequest({ endpoint: `passwords/${token}` }));
export const getRegistrationToken = token => call(makeRequest({ endpoint: `registrations/${token}` }));
export const getToken = () => sessionStorage.getItem('token');
export const getUser = () => call(makeRequest({ endpoint: 'sessions', token: true }));
export const login = (username, password) => call(makeRequest({ endpoint: 'sessions', method: 'POST', data: { username, password } }));
export const passwordChange = payload => call(makeRequest({ endpoint: 'passwords', method: 'PUT', data: payload, token: true }));
export const passwordRecovery = payload => call(makeRequest({ endpoint: 'users/password', method: 'PUT', data: payload }));
export const passwordReset = payload => call(makeRequest({ endpoint: 'users/password', method: 'PUT', data: payload }));
export const setToken = token => token == null ? false : sessionStorage.setItem('token', token);
export const signin = payload => call(makeRequest({ endpoint: 'users', method: 'POST', data: payload }));
export const signup = payload => call(makeRequest({ endpoint: 'registrations', method: 'POST', data: payload }));
export const unsetToken = () => sessionStorage.removeItem('token');
export const updateTos = payload => call(makeRequest({ endpoint: 'users/tos', method: 'PUT', data: payload, token: true }));
export const userDelete = payload => call(makeRequest({ endpoint: 'users', method: 'DELETE', data: payload, token: true }));
export const usernameRecovery = payload => call(makeRequest({ endpoint: 'users/username', method: 'PUT', data: payload }));
