import React from 'react';

const Error = ({ text = process.env.REACT_APP_ERROR_TEXT }) => {
  return (
    text.length === 0
      ? null
      : <div className="error">{text}</div>
  );
};

export default Error;
