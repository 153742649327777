import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { doRequest } from '../../../actions';
import { errorRead } from '../../../actions';
import Error from '../../elements/Error';
import SchemaValidator from '../../../lib/SchemaValidator';
import ShakeElement from '../../../lib/ShakeElement';

const mapDispatchToProps = (dispatch) => ({
    doRequest: payload => dispatch(doRequest('passwordChange', payload)),
    errorRead: () => dispatch(errorRead()),
});

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error
});

const _PasswordChange = ({ data, errorRead, doRequest, stateError }) => {

    const [processed, setProcessed] = useState(false);
    const [success, setSuccess] = useState(false);

    const [payload, setPayload] = useState(
        {
            password: "",
            password_old: "",
        }
    );

    const [errors, setErrors] = useState("");

    useEffect(
        () => {
            if (processed && stateError) {
                setErrors(stateError);
                errorRead();
                setProcessed(false);
            }
            if (errors.length > 0) {
                ShakeElement('form[name="passwordchange"]');
            }
            return () => null;
        },
        [errorRead, errors, processed, setErrors, setProcessed, stateError],
    );

    const handleChange = (e) => {
        let _payload = payload;
        _payload[e.target.name] = e.target.value;
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handlePasswordChange = (e) => {
        e.preventDefault();
        let v = SchemaValidator(payload, 'passwordchange');
        if (v.errors.length > 0) {
            setErrors(v.errors.map(el => el.schema.message).join("\n"));
        } else {
            setProcessed(true);
            doRequest(payload);
        }
    }

    if (data && processed) {
        setProcessed(false);
        setSuccess(true);
    }

    return (
        <form name="passwordchange">
            <legend>change your password</legend>
            {
                success
                    ?
                    <p>Password successfully changed!</p>
                    :
                    <fieldset>
                        <p>
                            <input type="password" placeholder="your actual password" name="password_old" required onChange={handleChange} />
                        </p>
                        <p>
                            <input type="password" placeholder="choose a new password" name="password" required onChange={handleChange} />
                            <label>minimum 8 chars with at least 1 capital letter, 1 number and 1 special char</label>
                        </p>
                        <p>
                            <button className="ok" onClick={handlePasswordChange}>Change</button>
                        </p>
                        <Error text={errors} />
                    </fieldset>
            }
        </form>
    );

};

const PasswordChange = connect(mapStateToProps, mapDispatchToProps)(_PasswordChange);

export default PasswordChange;