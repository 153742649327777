import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';

import { doRequest } from '../../../actions';
import { errorRead } from '../../../actions';
import Error from '../../elements/Error';
import LinkMail from '../../elements/LinkMail';
import SchemaValidator from '../../../lib/SchemaValidator';
import ShakeElement from '../../../lib/ShakeElement';

const urlPropsQueryConfig = {
    t: { type: UrlQueryParamTypes.string },
}

const mapDispatchToProps = (dispatch) => ({
    doRequest: payload => dispatch(doRequest('passwordReset', payload)),
    errorRead: () => dispatch(errorRead()),
    getPasswordToken: token => dispatch(doRequest('getPasswordToken', token))
});

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error
});

const _PasswordReset = ({ data, errorRead, doRequest, getPasswordToken, stateError, t }) => {
    const [canReset, setCanReset] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [loadedData, setLoadedData] = useState(false);

    const [processed, setProcessed] = useState(false);
    const [success, setSuccess] = useState(false);

    const [payload, setPayload] = useState(
        {
            password: "",
        }
    );

    const [errors, setErrors] = useState("");

    useEffect(
        () => {
            if (t) {
                if (!loadedData && !firstLoad) {
                    getPasswordToken(t);
                    setTimeout(() => setLoadedData(true), 500);
                    setFirstLoad(true);
                } else {
                    setFirstLoad(true);
                    setLoaded(true);
                }
            }
            if (!t || loaded) {
                if (stateError) {
                    setErrors(stateError);
                    errorRead();
                }
                if (errors.length > 0) {
                    ShakeElement('form[name="passwordreset"]');
                }
            }
            return () => null;
        },
        [errorRead, errors, firstLoad, getPasswordToken, loaded, loadedData, setErrors, setFirstLoad, setLoadedData, stateError, t],
    );

    const handleChange = (e) => {
        let _payload = payload;
        _payload[e.target.name] = e.target.value;
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handlePasswordReset = (e) => {
        e.preventDefault();
        let v = SchemaValidator(payload, 'passwordreset');
        if (v.errors.length > 0) {
            setErrors(v.errors.map(el => el.schema.message).join("\n"));
        } else {
            if (canReset) {
                payload.token = t;
            }
            setProcessed(true);
            doRequest(payload);
        }
    }

    if (data && loadedData) {
        setCanReset(true);
        setLoaded(true);
        setLoadedData(false);
    }

    if (data && processed) {
        setProcessed(false);
        setSuccess(true);
    }

    return (
        <section>
            <h1>reset password</h1>
            {
                success
                    ?
                    <form>
                        <legend>All done!</legend>
                        <p>
                            Your password has been changed. <Link to={'/'}>Log in now</Link> with your updated credentials.
                        </p>
                    </form>
                    :
                    canReset
                        ?
                        <form name="passwordreset">
                            <legend>Insert a new password</legend>
                            <fieldset>
                                <p>
                                    <input type="password" placeholder="choose a password" name="password" required onChange={handleChange} />
                                    <label>minimum 8 chars with at least 1 capital letter, 1 number and 1 special char</label>
                                </p>
                                <p>
                                    <button className="ok" onClick={handlePasswordReset}>Change</button>
                                </p>
                            </fieldset>
                            <Error text={errors} />
                            <LinkMail />
                        </form >
                        :
                        <form>
                            <p>Something is broken here...</p>
                        </form>
            }
        </section >
    );
};

const PasswordReset = addUrlProps({ urlPropsQueryConfig })(connect(mapStateToProps, mapDispatchToProps)(_PasswordReset));

export default PasswordReset;