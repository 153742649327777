import React from 'react';
import { Link } from 'react-router-dom';

import './Footer.scss';

const Footer = () => {
    return (
        <footer>
            <p>
                CyberDeck™ the Cypherpunk Tradable Card Game - Players Zone.
                <br />
                For more information, visit our <a href="https://cyberdeck.eu">public website</a>.
                <br />
                Join our <a href="http://t.me/cyberdeckccg" rel="noopener noreferrer" target="_blank">Telegram channel</a> and follow us on
            </p>
            <nav>
                <ul>
                    <li>
                        <a href="https://www.facebook.com/cyberdecktcg" target="_blank" rel="noopener noreferrer" title="Facebook">
                            <i className="fa fa-facebook-official" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/cyberdecktcg" target="_blank" rel="noopener noreferrer" title="Twitter">
                            <i className="fa fa-twitter-square" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/cyberdecktcg/" target="_blank" rel="noopener noreferrer" title="Instagram">
                            <i className="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.reddit.com/r/cyberdecktcg/" target="_blank" rel="noopener noreferrer" title="Reddit">
                            <i className="fa fa-reddit" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.twitch.tv/cyberdeckccg" target="_blank" rel="noopener noreferrer" title="Twitch">
                            <i className="fa fa-twitch" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCzFgFrXS1sBmU6ttbryYdfg" target="_blank" rel="noopener noreferrer" title="Youtube">
                            <i className="fa fa-youtube" aria-hidden="true"></i>
                        </a>
                    </li>
                </ul>
            </nav>
            <p>
                <small><Link to={'/privacy-policy'}>privacy policy</Link> - <Link to={'/cookie-policy'}>cookie policy</Link></small>
                <br />
                <small><Link to={'/general-terms-conditions'}>general terms and conditions of use</Link> - <Link to={'/general-terms-sale'}>general terms of sale</Link></small>
            </p>
        </footer>
    );
};

export default Footer;