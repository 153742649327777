import React from 'react';
import SimpleBarReact from "simplebar-react";

import GeneralTermsAndConditionsText from '../../elements/Texts/GeneralTermsAndConditionsText';

import '../../css/simplebar.min.css';

const GeneralTermsAndConditions = () => {

    return (
        <section className="large">
            <h1>general terms and conditions of use</h1>
            <SimpleBarReact className="boxed" autoHide="true">
                <GeneralTermsAndConditionsText date={process.env.REACT_APP_GTCU} />
            </SimpleBarReact>
        </section>
    );
};

export default GeneralTermsAndConditions;