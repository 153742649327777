import React from 'react';
import { Link } from 'react-router-dom';

import Mailto from 'react-protected-mailto';

const PrivacyPolicyText = ({ date }) => {
  return (
    <div className="left boxed-text">
      <p className="right">
        <small>Last update on {(new Date(date).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' }))}.</small>
      </p>
      <p>
        <strong>Cyberdeck S.r.l.</strong> is a limited liability company established under the law of Italy, having its registered office in Milan (Italy), Corso Venezia 54, 20122, VAT number 10381000966 (hereinafter referred to as <strong>"Cyberdeck"</strong>, <strong>"we"</strong> or the <strong>"Data Controller"</strong>).
        <br />
        Cyberdeck takes seriously into account the rights of its users (hereinafter referred to as <strong>"User"</strong>, <strong>"you"</strong> or <strong>"Data Subject"</strong>) regarding the protection of personal data and the obligations to which Cyberdeck is bound.
      </p>
      <p>
        This privacy policy (the <strong>"Policy"</strong>) describes how we process your personal data, which are collected through the portal available at <a href="https://cyberdeck.eu" target="_blank" rel="noopener noreferrer">https://cyberdeck.eu</a> and at the subdomains <a href="https://play.cyberdeck.eu" target="_blank" rel="noopener noreferrer">https://play.cyberdeck.eu</a> and <a href="https://forum.cyberdeck.eu" target="_blank" rel="noopener noreferrer">https://forum.cyberdeck.eu</a> (the <strong>"Portal"</strong>).
      </p>
      <p>
        With reference to the methods of management and processing of your personal data, pursuant to Legislative Decree 169/2003 and art. 13 of the EU Regulation no. 679/2016, Cyberdeck provides the following information.
      </p>
      <ol>
        <li>
          <big>The Data Controller</big>
          <br />
          The Data Controller is Cyberdeck S.r.l. in person of its legal representative <em>pro tempore</em>.
        </li>
        <li>
          <big>Contact details of the Data Controller</big>
          <br />
          You can contact us:
          <ul>
            <li>by e-mail at <Mailto email={process.env.REACT_APP_EMAIL_ADDRESS} headers={{ subject: process.env.REACT_APP_EMAIL_SUBJECT }} />; or</li>
            <li>by mail at Cyberdeck S.r.l., Corso Venezia 54, 20122 – Milan (Italy).</li>
          </ul>
        </li>
        <li>
          <big>Categories of personal data</big>
          <br />
          For the purposes described below, we collect the following type of personal data:
          <ul>
            <li>the e-mail address and the username you provide us when you create your account to access Cyberdeck platform (the <strong>"Platform"</strong>).</li>
            <li>technical data, i.e. information about your use and navigation on our Portal, such as your IP address.</li>
          </ul>
        </li>
        <li>
          <big>Mandatory provision of personal data</big>
          <br />
          The provision of your e-mail address and username is mandatory to access the Platform and play Cyberdeck’s games.
        </li>
        <li>
          <big>Purpose of personal data processing</big>
          <ol>
            <li>Your data will be processed lawfully and correctly within the scope and for the correct execution of the <Link to={'/general-terms-conditions'} target={'_blank'}>General Terms and Conditions</Link> of Use and for the fulfillment of the obligations connected to it.</li>
            <li>Your personal data will be processed for the following purposes:</li>
            <ol type="i">
              <li>the fulfillment of legal, regulatory or Community law obligations;</li>
              <li>the fulfillment of General Terms and Conditions of Use, located <Link to={'/general-terms-conditions'} target={'_blank'}>here</Link>, to consent you to access the Portal;</li>
              <li>if necessary, to exercise and/or defense of a right.</li>
            </ol>
          </ol>
        </li>
        <li>
          <big>Methods of data processing and storage</big>
          <ol>
            <li>The processing, paper and/or electronic, of data is carried out by means of the operations indicated in art. 4 paragraph 2) GDPR and precisely the: collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.</li>
            <li>The data will be processed in compliance with the principle of lawfulness, fairness, relevance and not excessive, in accordance with the provisions of legislation on the protection of personal data in such a way as to ensure the integrity, security and confidentiality.</li>
            <li>We use SSL/HTTPS throughout our website.</li>
            <li>The data processing will be carried out by Cyberdeck’s staff, formally appointed and adequately trained.</li>
          </ol>
        </li>
        <li>
          <big>Period of storage</big>
          <br />
          We store personal data until it is required for the purposes for which the personal data was collected. In general, we store your personal data until the deletion of your account, for any reason.
        </li>
        <li>
          <big>Legal basis for the processing</big>
          <br />
          The legal bases for the processing of your personal data for the purposes referred to in point 4 above are those referred to in Articles 6.1 b) ("processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at the request of the data subject prior to entering into a contract") and 6.1 c) ("processing is necessary for compliance with a legal obligation to which the controller is subject").
        </li>
        <li>
          <big>Data recipients</big>
          <ol>
            <li>We do not share your personal data to third parties, except for the cases listed below.</li>
            <li>In particular, the data may be shared with Authorities and/or subjects to whom they must be communicated pursuant to provisions of law or orders of Authorities. These Authorities and/or subjects will operate as autonomous data controllers.</li>
            <li>The data will not be disclosed. A complete and periodically updated list of the persons in charge of data processing can be requested by sending an e-mail to the Data Controller at the above-mentioned addresses.</li>
          </ol>
        </li>
        <li>
          <big>Transfer of personal data to a third country</big>
          <br />
          We do not transfer your personal data to any third country.
        </li>
        <li>
          <big>Source of personal data</big>
          <br />
          Your personal data has been freely provided and/or communicated by you to Cyberdeck.
        </li>
        <li>
          <big>Existence of automated operation</big>
          <br />
          Data processing does not involve any automated operation.
        </li>
        <li>
          <big>Data recipients</big>
          <br />
          With regard to personal data you provide us, you have the following rights:
          <ol type="i">
            <li>the right to withdraw your consent at any time;</li>
            <li>the right to access your personal data;</li>
            <li>the right to rectify your personal data;</li>
            <li>the right to obtain the erasure of your personal data;</li>
            <li>the right to data portability, i.e. the right to receive your personal data in a structured, commonly used and machine-readable format and the right to transmit those data to another data controller, where technically feasible.</li>
            <li>the right to obtain from us restriction of processing, where one of the following applies:
              <ol type="a">
                <li>the accuracy of the personal data is contested by you, for a period enabling us to verify the accuracy of the personal data;</li>
                <li>the processing is unlawful and you oppose the erasure of the personal data and requests the restriction of their use instead;</li>
                <li>we no longer need the personal data for the purposes of the processing, but they are required by you for the establishment, exercise or defense of legal claims;</li>
                <li>you have contested the processing on the basis of reasons of legitimate interest (see below) pending the ascertainment of our pre-eminent and legitimate reasons for the continuation of the processing;</li>
              </ol>
              <br />
              In the event that your personal data is subject to such restrictions, we will only process it with your consent or for the preparation, exercise or defense of legal action.
            </li>
            <li>the right to object to the processing on grounds of legitimate interest. In the event of processing of your personal data on the basis of a legitimate interest on our part, you shall have the right to object to the processing. In the event of your objection, we are obliged to stop the processing unless we can demonstrate legitimate reasons for the processing that outweigh your interest, rights and freedoms or the need to prepare, exercise or defend legal action. In the event of processing of your personal data on the basis of our legitimate interest, we believe that we are able to demonstrate the aforementioned overriding legitimate reasons, but each case will be considered individually.</li>
          </ol>
        </li>
        <li>
          <big>Embedded Contents</big>
          <br />
          The Portal includes embedded contents from Boostrap CDN (https://bootstrapcdn.com) and Google (https://fonts.googleapis.com). Embedded content from other websites behaves in the exact same way as if you have visited the other website.
        </li>
        <li>
          <big>Cookies</big>
          <br />
          The Portal uses only techincal anonymized non-tracking cookies. Refer to our <Link to={'/cookie-policy'} target={'_blank'}>Cookie Policy</Link>
        </li>
        <li>
          <big>Amendments</big>
          <br />
          We reserve the right to make amendments to this Policy, especially in order to comply with new technical requirements or requirements imposed by applicable legislation. We will notify you the new privacy policy by e-mail.
        </li>
        <li>
          <big>Provision of consent</big>
          <br />
          Do you consent to the processing of your personal data in the manner and for the purposes indicated in point 5 and 6 of this Policy? By consenting the data processing, you declare to have carefully read the contents of this Policy received by the Data Controller pursuant to art. 13 of EU Regulation no. 679/2016.
        </li>
      </ol>
    </div>
  );
};

export default PrivacyPolicyText;
