import React from 'react';
import SimpleBarReact from "simplebar-react";

import GeneralTermsSaleText from '../../elements/Texts/GeneralTermsSaleText';

import '../../css/simplebar.min.css';

const GeneralTermsSale = () => {

    return (
        <section className="large">
            <h1>general terms of sale</h1>
            <SimpleBarReact className="boxed" autoHide="true">
                <GeneralTermsSaleText date={process.env.REACT_APP_GTS} />
            </SimpleBarReact>
        </section>
    );
};

export default GeneralTermsSale;