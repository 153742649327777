import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
    errorRead,
    getProfile,
    login
} from '../../../actions';
import Error from '../../elements/Error';
import LinkMail from '../../elements/LinkMail';
import SchemaValidator from '../../../lib/SchemaValidator';
import ShakeElement from '../../../lib/ShakeElement';


const mapDispatchToProps = (dispatch) => ({
    errorRead: () => dispatch(errorRead()),
    getProfile: () => dispatch(getProfile()),
    login: payload => dispatch(login(payload))
});

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error,
    user: state.user
});

const _Login = ({ data, doRequest, errorRead, login, stateError }) => {
    const initialPayload = {
        username: "",
        password: "",
    };

    const [errors, setErrors] = useState("");
    const [processed, setProcessed] = useState(false);
    const [payload, setPayload] = useState(initialPayload);

    useEffect(
        () => {
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            if (errors.length > 0) {
                ShakeElement('form[name="login"]');
            }
            return () => null;
        },
        [errorRead, errors, setErrors, stateError]
    );

    const handleChange = (e) => {
        let _payload = payload;
        _payload[e.target.name] = e.target.value;
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handleLogin = (e) => {
        e.preventDefault();
        let v = SchemaValidator(payload, 'login');
        if (v.errors.length > 0) {
            setErrors(v.errors.map(el => el.schema.message).join("\n"));
        } else {
            login(payload);
        }
    }

    if (data && processed) {
        setErrors("");
        setProcessed(false);
        getProfile();
    }

    return (
        <section>
            <h1>players zone</h1>
                <form name="login">
                    <legend>Log in using your in-game credentials</legend>
                    <fieldset>
                        <p>
                            <input type="text" placeholder="username" name="username" onChange={handleChange} />
                        </p>
                        <p>
                            <input type="password" placeholder="password" name="password" onChange={handleChange} />
                        </p>
                        <p>
                            <button className="ok" onClick={handleLogin}>Log in</button>
                        </p>
                    </fieldset>
                    <Error text={errors} />
                    <p>
                        Lost password? <Link to={'/password-recovery'}>Recover it</Link>! or <Link to={'/username-recovery'}>forgot the username</Link>?
                    </p>
                    <p>
                        Don't have an account yet?
                        <br />
                        <Link to={'/early-access'}>Get early access</Link> and become a tester!
                    </p>
                    <LinkMail />
                </form>
        </section>
    );
};

const Login = connect(mapStateToProps, mapDispatchToProps)(_Login);

export default Login;