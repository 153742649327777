import React, { useState, useEffect } from 'react';

import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import Account from './Account';
import Activation from './Activation';
import CookiePolicy from './CookiePolicy';
import EarlyAccess from './EarlyAccess';
import GeneralTermsAndConditions from './GeneralTermsAndConditions';
import GeneralTermsSale from './GeneralTermsSale';
import Home from './Home';
import Login from './Login';
import NoMatch from './NoMatch';
import PasswordRecovery from './PasswordRecovery';
import PasswordReset from './PasswordReset';
import PrivacyPolicy from './PrivacyPolicy';
import Registration from './Registration';
import UsernameRecovery from './UsernameRecovery';

import './index.scss';

const mapStateToProps = state => ({
  auth: state.user.username,
  user: state.user
});

const _Main = ({ auth, user }) => {
  const [mustUpdateTos, setMustUpdateTos] = useState(0);

  useEffect(
    () => {
      if (auth) {
        setMustUpdateTos(
          (user.privacy_date == null || (new Date(process.env.REACT_APP_PRIVACY)) > (new Date(user.privacy_date)) ? 1 : 0)
          +
          (user.gtcu_date == null || (new Date(process.env.REACT_APP_GTCU)) > (new Date(user.gtcu_date)) ? 2 : 0)
          +
          (user.gts_date == null || (new Date(process.env.REACT_APP_GTS)) > (new Date(user.gts_date)) ? 4 : 0)
        )
      }
      return () => null;
    },
    [auth, setMustUpdateTos, user]
  );

  return (
    <main className={(auth ? 'private' : 'public')}>
      {auth
        ?
        mustUpdateTos
          ?
          <Switch>
            <Route exact path="/" render={(props) => <Home {...props} updateTos={mustUpdateTos} />} />
            <Route component={NoMatch} />
          </Switch>
          :
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/account" component={Account} />
            <Route exact path="/cookie-policy" component={CookiePolicy} />
            <Route exact path="/general-terms-conditions" component={GeneralTermsAndConditions} />
            <Route exact path="/general-terms-sale" component={GeneralTermsSale} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route component={NoMatch} />
          </Switch>
        :
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/activation" component={Activation} />
          <Route exact path="/cookie-policy" component={CookiePolicy} />
          <Route exact path="/early-access" component={EarlyAccess} />
          <Route exact path="/general-terms-conditions" component={GeneralTermsAndConditions} />
          <Route exact path="/general-terms-sale" component={GeneralTermsSale} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/password-recovery" component={PasswordRecovery} />
          <Route exact path="/password-reset" component={PasswordReset} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/registration" component={Registration} />
          <Route exact path="/username-recovery" component={UsernameRecovery} />
          <Route component={NoMatch} />
        </Switch>
      }
    </main>
  );
};

const Main = connect(mapStateToProps)(_Main);

export default Main;
