import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
    doRequest,
    errorRead,
    getProfile
} from '../../../actions';
import Error from '../../elements/Error';
import LinkMail from '../../elements/LinkMail';
import Modal from '../../elements/Modal';

import GeneralTermsAndConditions from '../Main/GeneralTermsAndConditions';
import GeneralTermsSale from '../Main/GeneralTermsSale';
import PrivacyPolicy from '../Main/PrivacyPolicy';

const mapDispatchToProps = (dispatch) => ({
    doRequest: payload => dispatch(doRequest('updateTos', payload)),
    errorRead: () => dispatch(errorRead()),
    getDownloads: () => dispatch(doRequest('getDownloads')),
    getProfile: () => dispatch(getProfile()),
});

const mapStateToProps = state => ({
    auth: state.user.username,
    data: state.data,
    stateError: state.error,
    user: state.user
});

const _Home = ({ auth, data, doRequest, errorRead, getDownloads, getProfile, stateError, updateTos = 0, user }) => {
    const initialPayload = {
        privacy: 0,
        gtcu: 0,
        gtcu_1: 0,
        gts: 0,
        gts_1: 0
    };

    const [downloads, setDonwloads] = useState(false);
    const [errors, setErrors] = useState("");
    const [errorTos1, setErrorTos1] = useState("");
    const [errorTos2, setErrorTos2] = useState("");
    const [errorTos3, setErrorTos3] = useState("");
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false);
    const [processed, setProcessed] = useState(false);
    const [processed2, setProcessed2] = useState(false);
    const [payload, setPayload] = useState(initialPayload);

    useEffect(
        () => {
            if (!processed2) {
                getDownloads();
                setProcessed2(true);
                setErrors("");
            }
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            return () => null;
        },
        [auth, errorRead, errors, getDownloads, setErrors, stateError, setProcessed2, user]
    );

    const handleAcceptTos = (e, tos) => {
        e.preventDefault();
        switch (tos) {
            case 'privacy':
                if (!payload.privacy) {
                    setErrorTos1("Please check the above tick to proceed");
                    return;
                }
                break;
            case 'gtcu':
                if ((payload['gtcu'] + payload['gtcu_1']) < 2) {
                    setErrorTos2("Please check the above ticks to proceed");
                    return;
                }
                break;
            case 'gts':
                if ((payload['gts'] + payload['gts_1']) < 2) {
                    setErrorTos3("Please check the above ticks to proceed");
                    return;
                }
                break;
            default:
                return;
        }
        doRequest({ type: tos });
        setProcessed(true);
    }

    const handleChangeTos = (e) => {
        let _payload = payload;
        _payload[e.target.name] = e.target.value;
        if (e.target.type === 'checkbox') {
            _payload[e.target.name] = e.target.checked ? 1 : 0;
        }
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    if (data && processed) {
        setErrors("");
        setProcessed(false);
        getProfile();
    }

    if (data && processed2) {
        setProcessed2(false);
        setDonwloads(data);
    }

    return (
        <section>
            <h1>players zone</h1>
            <form>
                {auth
                    ?
                    <fieldset>
                        <legend>You're logged in as <strong>{user.username}</strong></legend>
                    </fieldset>
                    :
                    <p>
                        CyberDeck™ is in closed alpha and we're seeking testers! <Link to={"/early-access"}>Join us becoming an early adopter</Link >.
                    </p>
                }
                {auth &&
                    <>
                        {updateTos === 0
                            ?
                            <>
                            </>
                            :
                            <fieldset>
                                {
                                    (updateTos & 1
                                        ?
                                        <>
                                            <blockquote>
                                                We've updated our privacy policy, please <Link to="#" onClick={() => setModal(true)}>review it</Link> and consent to it to before continuing to use our service.
                                            </blockquote>
                                            <p>
                                                <input type="checkbox" id="privacy" name="privacy" onChange={handleChangeTos} defaultValue="1" />
                                                <label className={'left'} htmlFor={`privacy`}>
                                                    <span />
                                                    I read and consent to your <Link to="#" onClick={() => setModal(true)}>privacy policy</Link>
                                                </label>
                                            </p>
                                            <p>
                                                <button className="ok" onClick={(e) => handleAcceptTos(e, 'privacy')}>Ok, I consent</button>
                                            </p>
                                            <Error text={errorTos1} />
                                            <Modal show={modal}>
                                                <div>
                                                    <p className="right"><Link to="#" onClick={() => setModal(false)}>X Close</Link></p>
                                                    <PrivacyPolicy />
                                                </div>
                                            </Modal>
                                        </>
                                        : <></>
                                    )
                                }
                                {
                                    (updateTos & 2
                                        ?
                                        <>
                                            <blockquote>
                                                We've updated our general terms and conditions of use, please <Link to="#" onClick={() => setModal2(true)}>review it</Link> and accept it to before continuing to use our service.
                                        </blockquote>
                                            <p>
                                                <input type="checkbox" id="gtcu" name="gtcu" onChange={handleChangeTos} defaultValue="1" />
                                                <label className={'left'} htmlFor={`gtcu`}>
                                                    <span />
                                                    I read and consent to your <Link to="#" onClick={() => setModal2(true)}>general terms and condition of use</Link>
                                                </label>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="gtcu_1" name="gtcu_1" onChange={handleChangeTos} defaultValue="1" />
                                                <label className={'left'} htmlFor={`gtcu_1`}>
                                                    <span />
                                                    Pursuant to articles 1341 and 1342 of the Italian Civil Code, I specifically accept the following Sections of the above Agreement: 2 "<b>Grant of Licence</b>", 3 "<b>Licence Limitations</b>", 4 "<b>Platform Features</b>", 6 "<b>Platform Ownership</b>", 7 "<b>Pre-Loaded Software</b>", 8 "<b>Consent to Monitor</b>", 9 "<b>Limitations of Liability</b>", 10 "<b>Indemnity</b>", 11 "<b>Amendments</b>", 12 "<b>Duration</b>", 13 "<b>Termination</b>", 14 "<b>Governing Law and Jurisdiction</b>", 16 "<b>General</b>".
                                            </label>
                                            </p>
                                            <p>
                                                <button className="ok" onClick={(e) => handleAcceptTos(e, 'gtcu')}>Ok, I consent</button>
                                            </p>
                                            <Error text={errorTos2} />
                                            <Modal id={"modal2"} show={modal2}>
                                                <div>
                                                    <p className="right"><Link to="#" onClick={() => setModal2(false)}>X Close</Link></p>
                                                    <GeneralTermsAndConditions />
                                                </div>
                                            </Modal>
                                        </>
                                        : <></>
                                    )
                                }
                                {
                                    (updateTos & 4
                                        ?
                                        <>
                                            <blockquote>
                                                We've updated our general terms of sale, please <Link to="#" onClick={() => setModal3(true)}>review it</Link> and accept it to before continuing to use our service.
                                        </blockquote>
                                            <p>
                                                <input type="checkbox" id="gts" name="gts" onChange={handleChangeTos} defaultValue="1" />
                                                <label className={'left'} htmlFor={`gts`}>
                                                    <span />
                                                    I read and consent to your <Link to="#" onClick={() => setModal3(true)}>general terms of sale</Link>
                                                </label>
                                            </p>
                                            <p>
                                                <input type="checkbox" id="gts_1" name="gts_1" onChange={handleChangeTos} defaultValue="1" />
                                                <label className={'left'} htmlFor={`gts_1`}>
                                                    <span />
                                                    Pursuant to articles 1341 and 1342 of the Italian Civil Code, I specifically accept the following Sections of the above Agreement: 3 "<b>Liability for Unauthorized Transactions</b>", 5 "<b>Terms of payment</b>", 7 "<b>Company’s Undertakings and Warranty on Cyberdeck Content</b>", 8 "<b>Customer’s right to withdraw and refund</b>", 9 "<b>Amendments to General Terms of Sale</b>", 10 "<b>Governing Law and Jurisdiction</b>".
                                            </label>
                                            </p>
                                            <p>
                                                <button className="ok" onClick={(e) => handleAcceptTos(e, 'gts')}>Ok, I consent</button>
                                            </p>
                                            <Error text={errorTos3} />
                                            <Modal id={"modal3"} show={modal3}>
                                                <div>
                                                    <p className="right"><Link to="#" onClick={() => setModal3(false)}>X Close</Link></p>
                                                    <GeneralTermsSale />
                                                </div>
                                            </Modal>
                                        </>
                                        : <></>
                                    )
                                }
                            </fieldset>
                        }
                    </>
                }
                {updateTos === 0
                    ?
                    <>
                        <fieldset>
                            <LinkMail text={"Download the latest version of the game client and give us some feedback writing to"} />
                            <p>
                                We're working on a lots of new features, be always updated on our <a href="http://t.me/cyberdeckccg" rel="noopener noreferrer" target="_blank">Telegram channel</a>
                            </p>
                        </fieldset>
                        {downloads &&
                            <div className="blackbox">
                                <h2>Download</h2>
                                {
                                    Object.keys(downloads).map((osName, _i) => {
                                        let osIcon = osName === 'osx' ? 'apple' : osName;
                                        return (
                                            <div className="download" key={`download_${_i}`}>
                                                <h3>
                                                    <a href={`${process.env.REACT_APP_API_URL}/downloads/${osName}`}>
                                                        <i className={`fa fa-${osIcon}`}></i> {downloads[osName].name} - v{downloads[osName].version}
                                                    </a>
                                                </h3>
                                                {
                                                    downloads[osName].md5 != null && <small>MD5 sum {downloads[osName].md5}</small>
                                                }
                                            </div>
                                        );
                                    })
                                }
                                <p>CyberDeck™ is easy on resources, running on machines with 512mb RAM, 600mb of free space and a 2ghz CPU, WARNING! if you are having some issue with linux client remove the Cyberdeck Preferences with .config/unity3d/Cyberdeck*</p>
                            </div>
                        }
                    </>
                    :
                    <>
                    </>
                }
            </form>
        </section>
    );
};

const Home = connect(mapStateToProps, mapDispatchToProps)(_Home);

export default Home;