import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import Nav from './Nav';
import './Header.scss';

const mapStateToProps = (state) => ({
});

const Header = connect(mapStateToProps)((() => ({ dispatch }) => (
    (
        <header>
            <div id="logo">
                <Link to="/"><img alt="Cyberdeck" src="/static/images/cyberdeck_logo.png" /></Link>
                <cite>CLOSED ALPHA</cite>
            </div>
            <Nav />
        </header>
    )
))());

export default Header;