import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';

import { doRequest, errorRead, setToken } from '../../../actions';

const urlPropsQueryConfig = {
    t: { type: UrlQueryParamTypes.string },
}

const mapDispatchToProps = (dispatch) => ({
    doRequest: payload => dispatch(doRequest('enroll', payload)),
    errorRead: () => dispatch(errorRead()),
    getActivationToken: token => dispatch(doRequest('getActivationToken', token)),
    setToken: token => dispatch(setToken(token))
});

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error
});

const _Activation = ({ data, errorRead, doRequest, getActivationToken, stateError, setToken, t }) => {
    const [canActivate, setCanActivate] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [loadedData, setLoadedData] = useState(false);

    const [processed, setProcessed] = useState(false);
    const [success, setSuccess] = useState(false);

    const payload = { token: t };

    const [errors, setErrors] = useState("");

    useEffect(
        () => {
            if (t) {
                if (!loadedData && !firstLoad) {
                    getActivationToken(t);
                    setTimeout(() => setLoadedData(true), 500);
                    setFirstLoad(true);
                } else {
                    setFirstLoad(true);
                    setLoaded(true);
                }
            } else {
                if (stateError) {
                    setErrors(stateError);
                    errorRead();
                    setCanActivate(false);
                }
            }
            if (success) {
                setTimeout(() => { window.location.replace('/'); }, 2500);
            }
            return () => true;
        },
        [errorRead, errors, firstLoad, getActivationToken, loaded, loadedData, setErrors, setFirstLoad, setLoadedData, stateError, success, t],
    );

    if (data && loadedData) {
        setCanActivate(true);
        setLoaded(true);
        setLoadedData(false);
        doRequest(payload);
        setTimeout(() => {
            setProcessed(true)
        }, 2000);
    }

    if (data && processed) {
        setProcessed(false);
        setToken(data);
        setTimeout(() => {
            setSuccess(true);
            window.location.reload();
        }, 2000);
    }

    return (
        <section>
            <h1>activation</h1>
            {
                processed
                    ?
                    <form>
                        <legend>Activation complete!</legend>
                        <p>
                            ...redirecting
                        </p>
                    </form>
                    :
                    canActivate
                        ?
                        <form>
                            <legend>Activating...</legend>
                            <p>
                                ...just a few seconds
                            </p>
                        </form>
                        :
                        <form>
                            <p>Something is broken here...</p>
                        </form>
            }
        </section >
    );
};

const Activation = addUrlProps({ urlPropsQueryConfig })(connect(mapStateToProps, mapDispatchToProps)(_Activation));

export default Activation;