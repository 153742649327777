import React from 'react';

import './Modal.scss';

const Modal = props => {
    var classes = "modal";
    if (props.show) {
        classes += " shown"
    }
    var id = props.id || "modal";
    return (
        <div id={id} className={classes}>
            {props.children}
        </div>
    );
};

export default Modal;
