import React from 'react';

import Mailto from 'react-protected-mailto';

const LinkMail = ({ text = process.env.REACT_APP_EMAIL_TEXT, email = process.env.REACT_APP_EMAIL_ADDRESS, subject = process.env.REACT_APP_EMAIL_SUBJECT }) => {
  return (
    <p>{text} <Mailto email={email} headers={{ subject }} /></p>
  );
};

export default LinkMail;
