import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { doRequest, errorRead, getProfile } from '../../../actions';
import Error from '../Error';
import SchemaValidator from '../../../lib/SchemaValidator';
import ShakeElement from '../../../lib/ShakeElement';

const mapDispatchToProps = (dispatch) => ({
    doRequest: payload => dispatch(doRequest('emailChange', payload)),
    doRequest2: payload => dispatch(doRequest('emailChangeConfirm', payload)),
    doRequest3: payload => dispatch(doRequest('emailChangeDismiss', payload)),
    errorRead: () => dispatch(errorRead()),
    getProfile: () => dispatch(getProfile())
});

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error,
    user: state.user
});

const _EmailChange = ({ data, errorRead, doRequest, doRequest2, doRequest3, getProfile, stateError, user }) => {

    const [processed, setProcessed] = useState(false);
    const [success, setSuccess] = useState(false);
    const [processed2, setProcessed2] = useState(false);
    const [success2, setSuccess2] = useState(false);
    const [processed3, setProcessed3] = useState(false);
    const [changeEmail, setChangeEmail] = useState(user.change_email);

    const [payload, setPayload] = useState(
        {
            email: "",
            password: "",
        }
    );

    const [payload2, setPayload2] = useState(
        {
            code: ""
        }
    );


    const [errors, setErrors] = useState("");

    useEffect(
        () => {
            if (processed && stateError) {
                setErrors(stateError);
                errorRead();
                setProcessed(false);
            }
            if (errors.length > 0) {
                ShakeElement('form[name="emailchange"]');
            }
            return () => null;
        },
        [errorRead, errors, processed, setErrors, setProcessed, stateError],
    );

    const handleChange = (e) => {
        let _payload = payload;
        _payload[e.target.name] = e.target.value;
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handleEmailChange = (e) => {
        e.preventDefault();
        let v = SchemaValidator(payload, 'emailchange');
        if (v.errors.length > 0) {
            setErrors(v.errors.map(el => el.schema.message).join("\n"));
        } else {
            setProcessed(true);
            doRequest(payload);
        }
    }

    const handleChangeConfirm = (e) => {
        let _payload = payload2;
        _payload[e.target.name] = e.target.value;
        setPayload2({
            ...payload2,
            ..._payload,
        });
    };

    const handleEmailChangeConfirm = (e) => {
        e.preventDefault();
        setProcessed2(true);
        doRequest2(payload2);
    }

    const handleEmailChangeDismiss = (e) => {
        e.preventDefault();
        setProcessed3(true);
        doRequest3();
    }

    if (data && processed) {
        setErrors("");
        setProcessed(false);
        setSuccess(true);
        setTimeout(() => { document.getElementById('confirmationCode').value = '' }, 500);
    }

    if (data && processed2) {
        setErrors("");
        setProcessed2(false);
        setSuccess2(true);
        getProfile();
    }

    if (data && processed3) {
        setErrors("");
        setProcessed3(false);
        setSuccess(false);
        setChangeEmail(false);
    }


    return (
        <form name="emailchange">
            <legend>change your email</legend>
            {
                (success || changeEmail)
                    ?
                    success2
                        ?
                        <fieldset>
                            <p>Email successfully changed!</p>
                            <p>
                                Your registered email address is now <strong>{user.email}</strong>
                            </p>
                        </fieldset>
                        :
                        <fieldset>
                            <p>
                                we sent you an email to the new address with a confirmation code, please insert it below
                            </p>
                            <p>
                                <input id="confirmationCode" type="password" placeholder="confirmation code" name="code" required onChange={handleChangeConfirm} defaultValue="" autoComplete="new-password" />
                            </p>
                            <p>
                                <button className="ok" onClick={handleEmailChangeConfirm}>Confirm</button>
                            </p>
                            <p>
                                <button className="ko" onClick={handleEmailChangeDismiss}>Nevermind, don't change</button>
                            </p>
                            <Error text={errors} />
                        </fieldset>
                    :
                    <fieldset>
                        {user.email
                            ?
                            <p>
                                Your registered email address is <strong>{user.email}</strong>
                            </p>
                            :
                            <blockquote>it's seem that you didn't registered an email address yet, please provide a valid one!</blockquote>
                        }
                        <p>
                            <input type="password" placeholder="your actual password" name="password" required onChange={handleChange} autoComplete="new-password" />
                        </p>
                        <p>
                            <input type="email" placeholder="insert email address" name="email" required onChange={handleChange} />
                            <label>you're going to receive a confirmation code to this new email address.</label>
                        </p>
                        <p>
                            <button className="ok" onClick={handleEmailChange}>{(user.email ? `Change` : `Register`)}</button>
                        </p>
                        <Error text={errors} />
                    </fieldset>
            }
        </form>
    );

};

const EmailChange = connect(mapStateToProps, mapDispatchToProps)(_EmailChange);

export default EmailChange;