import React from 'react';
import { Link } from 'react-router-dom';

import Mailto from 'react-protected-mailto';

const GeneralTermsSaleText = ({ date }) => {
  return (
    <div className="left boxed-text">
      <p className="right">
        <small>Last update on {(new Date(date).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' }))}.</small>
      </p>
      <p>PLEASE NOTE THAT BY MAKING A PURCHASE, YOU ACCEPT AND AGREE TO BE BOUND BY THESE GENERAL TERMS OF SALE. PLEASE READ THEM CAREFULLY.</p>
      <ol>
        <li><big>General Rules</big>
          <ol>
            <li>Cyberdeck S.r.l., is a limited liability company established under the law of Italy, having its registered office in Milan (Italy), Corso Venezia 54, 20122, VAT number 10381000966 (hereinafter referred to as "<strong>Cyberdeck</strong>", "<strong>we</strong>" or "<strong>us</strong>").</li>
            <li>These general terms of sale (the "<strong>General Terms of Sale</strong>") constitute a binding, legal agreement between you (hereinafter referred to as "<strong>you</strong>") and Cyberdeck.</li>
            <li>These General Terms of Sale govern your purchase of digital content, content subscriptions, in-game content, in-game feature, or any other digital product (each a "<strong>Cyberdeck Content</strong>") available through as https://www.cyberdeck.eu/ (the "<strong>Store</strong>").</li>
            <li>These General Terms of Sale supplement the General Terms and Conditions of Use, located <Link to={'/general-terms-conditions'} target={'_blank'}>here</Link>, and the Privacy Policy, located <Link to={'/privacy-policy'} target={'_blank'}>here</Link>.</li>
          </ol>
        </li>
        <li><big>Requirements to make a purchase on the Store</big>
          <ol>
            <li>To make a purchase on the Store you must have a Cyberdeck account (the "<strong>Account</strong>"). If you do not have an Account, you need to create one, following the provisions set forth in the General Terms and Conditions of Use, located <Link to={'/general-terms-conditions'} target={'_blank'}>here</Link>.</li>
            <li>To create an Account, you must accept: (i) the General Terms and Conditions of Use and (ii) the Privacy Policy, located <Link to={'/privacy-policy'} target={'_blank'}>here</Link>.</li>
            <li>To purchase a Cyberdeck Content you have to be "<strong>natural person</strong>" and be over 18 (eighteen) years old or you have to be an adult in your country of residence.</li>
            <li></li>
          </ol>
        </li>
        <li><big>Liability for Unauthorized Transactions</big>
          <br />
          You are solely responsible for maintaining the confidentiality of your login information, and you hereby accept sole responsibility for all activities that occur under your Account, unless as otherwise specified hereunder. You are responsible for all purchases of Cyberdeck Content made through your Account. Cyberdeck shall not be liable for any loss that you may incur as a result of unauthorized purchase or uses of your Account, when such loss is due to your failure at maintaining the security and confidentiality of your Account.
        </li>
        <li><big>Pricing</big>
          <ol>
            <li>The price payable by you to purchase the Cyberdeck Content is the price displayed on the Store, which will be the same as the one showed by the checkout page before you finalize your purchase (the "<strong>Purchase Price</strong>").</li>
            <li>The Purchase Price is displayed in Euro. For purchases outside the Euro zone, the exchange rate is demanded to each Payment Gateway.</li>
            <li>Cyberdeck reserves the right to change the Purchase Price and availability of any and all Cyberdeck Content at any time without notice; provided that such Purchase Price change will not affect your Purchase Price for orders that Cyberdeck has already received from you and processed.</li>
          </ol>
        </li>
        <li><big>Terms of payment</big>
          <ol>
            <li>The Purchase Price can be paid to Cyberdeck as the same time as the transmission of the order with PayPal, by PayPal Inc. and any other payment gateway present on https://play.cyberdeck.eu or in Cyberdeck app client   (each a "<strong>Payment Gateway</strong>"). At the end of the proceedings of the order’s transmission, you will be addressed, according to the preference expressed, to the page of the Payment Gateway you have chosen. The Purchase Price of the order will be charged at the time of the order’s acquisition.</li>
            <li>You will receive a confirmatory e-mail by the Payment Gateway you have chosen. At no time of the purchase’s proceedings Cyberdeck can guarantee the display of your financial information. </li>
            <li>In the event that you have exercised the Right of Withdrawal referring to the purchased Cyberdeck Content, pursuant the following Section 8, the Purchase Price will be reimbursed on the account of the Payment Gateway you have chosen.</li>
            <li>Under no circumstances Cyberdeck could be held liable for possible damages, direct or non-direct, caused by a delay in case of a failure release of the Purchase Price by the Payment Gateway you have chosen.</li>
          </ol>
        </li>
        <li><big>Delivery of Cybederck Content</big>
          <br />
          Cyberdeck will strive to deliver the purchased Cyberdeck Content to you within a reasonable amount of time. However, occasionally, technical problems and/or a manual review of the order may delay or prevent delivery of the purchased Cyberdeck Content. Your remedy with respect to a digital content that is not delivered within a reasonable amount of time is to either receive a replacement of such Cyberdeck Content, or a refund of the Purchase Price paid for such Cyberdeck Content. Cyberdeck will not supply any Cyberdeck Content to you until your payment has been processed.
        </li>
        <li><big>Company’s Undertakings and Warranty on Cyberdeck Content</big>
          <ol>
            <li>Cyberdeck undertakes to describe and present Cyberdeck Content offered on sale in the best possible way.</li>
            <li>Cyberdeck undertakes to offer contents that comply with the description given by Cyberdeck and that show the quality and performance which are normal in goods of the same type.</li>
            <li>If the purchased Cyberdeck Content is non-compliant, you must denounce it to Cyberdeck in writing by e-mail at <Mailto email={process.env.REACT_APP_EMAIL_ADDRESS} headers={{ subject: process.env.REACT_APP_EMAIL_SUBJECT }} />. </li>
            <li>Purchases of Cyberdeck Content are governed by Italian Consumer Code’s provisions (Legislative Decree 6 September 2005 No. 205, as amended) regarding the warranty for defects and lack of conformity of the goods. Therefore, pursuant to article 130 of the Italian Consumer Code, you will have the right to ask for the replacement of the defective or non-compliant content or for the termination of the contract of sale, with following refund of the amount paid to purchase the Cyberdeck Content.</li>
            <li>Cyberdeck will be held liable only if the lack of conformity occurs within 2 (two) years from the delivery of the Cyberdeck Content. You must denounce the lack of conformity within 2 (two) months from the date of the discovery of the defect or the lack of conformity.</li>
            <li>If the required replacement of the defective or non-compliant Cyberdeck Content is not possible, you will get a refund of the amount paid to purchase the Cyberdeck Content.</li>
          </ol>
        </li>
        <li><big>Customer’s right to withdraw and refund</big>
          <ol>
            <li>Pursuant to Articles 52 and following of the Italian Consumer Code, you have the right to withdraw from these General Terms of Sale, without the duty to state reason and without penalty (the "<strong>Right of Withdrawal</strong>"), within 14 (fourteen) working days from the day the Cyberdeck Content was delivered to you (the "<strong>Withdrawal Period</strong>").</li>
            <li>Please note that, pursuant to article 59, letter o) of the Italian Consumer Code, the right of withdrawal is excluded as soon as you have expressly consented to the performance of the Cyberdeck Content, which is a digital content provided on a non-material support. Therefore, you will lose your Right of Withdrawal as soon as you open the cards pack you purchased or you start using the other purchased User Content.</li>
            <li>In order to exercise your Right of Withdrawal, please contact us at <Mailto email={process.env.REACT_APP_EMAIL_ADDRESS} headers={{ subject: process.env.REACT_APP_EMAIL_SUBJECT }} />. To meet the withdrawal deadline, you must send us your communication concerning the exercise of the right of withdrawal before the Withdrawal Period has expired. </li>
            <li>If you decide to withdraw from the purchase, we will refund to you the payments received from you without undue delay and in any event no later than 14 (fourteen) days from the day on which we are informed about your decision to withdraw from the purchase. The refund will be done on the same Payment Gateway you have chosen to make the purchase.</li>
          </ol>
        </li>
        <li><big>Amendments to General Terms of Sale</big>
          <ol>
            <li>Cyberdeck reserves the right to modify this General Terms of Sale at any time. The new General Terms of Sale will enter into force from their publication on Cyberdeck website.</li>
            <li>The General Terms of Sale referred to each purchase are the one in force at the time of the purchase.</li>
          </ol>
        </li>

        <li><big>Governing Law and Jurisdiction</big>
          <ol>
            <li>These General Rules of Purchase and any purchase of Cyberdeck Content are regulated by the Italian law, regardless of where the purchase is made.</li>
            <li>For any dispute that may arise regarding the validity, the execution, the interpretation or the termination of these General Rules of Purchase exclusively referred to the Court of Milan.</li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default GeneralTermsSaleText;
