import React from 'react';
import { Provider } from "react-redux";

import store from "./store/index";
import { configureUrlQuery } from 'react-url-query';
import RoutedApp from './RoutedApp';

const history = require("history").createBrowserHistory();
configureUrlQuery({ history });

const App = () => (
    <Provider store={store}>
        <RoutedApp />
    </Provider>
);

export default App;